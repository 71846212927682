import { LegislativeSessionWithState } from '@enview/interface/types/LegislativeSession';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { BillDetailed, Bill } from '@enview/interface/types/bills/Bill';
import { BillAlias, BillSummary } from '@enview/interface/types/bills/BillSummary';
import { legislatures } from '../data';
import { sortBillByAlpha } from '../utils';
import { BillAction } from '@enview/interface/types/bills/Bill';
import moment from 'moment';
import {
  partyString,
  democratPartyIdentifier,
  republicanPartyIdentifier,
} from '../utils';
import {
  BillStatus,
  SearchActionFilter,
  SearchActionFilterType,
} from '@enview/interface/types/Bill';

export const BillSidebarTabs = {
  BILL_TEXT: 'Bill Text',
  DETAILS: 'Details',
  INTELLIGENCE: 'Intelligence',
  ACTIVITY: 'Activity',
};

export type AdvancedFilterOption = 'any' | 'all' | 'none';

export const getBillAlias = (
  userAliases: BillAlias[],
  teamModeId: number,
): BillAlias | undefined => {
  return (
    userAliases && userAliases.find((alias: BillAlias) => alias.teamId === teamModeId)
  );
};

export const getBillTitle = (
  session: LegislativeSessionWithState,
  number: string,
  billAlias?: BillAlias,
): string => {
  let billState = '';
  const legValue =
    session.state.abbreviation === 'usa'
      ? 'FEDERAL'
      : session.state.abbreviation.toUpperCase();
  const legislature = legislatures.find((leg) => leg.value === legValue);
  if (legislature) {
    billState = legislature.label;
  }
  return billAlias
    ? `${billState} ${number} (${billAlias.alias})`
    : `${billState} ${number}`;
};

export const getAbbreviatedBillTitle = (
  session: LegislativeSessionWithState,
  number: string,
  billAlias?: BillAlias,
): string => {
  const billState =
    session.state.abbreviation === 'usa'
      ? 'FED'
      : session.state.abbreviation.toUpperCase();
  return billAlias
    ? `${billState} ${number} (${billAlias.alias})`
    : `${billState} ${number}`;
};

export const uniqueBills = (
  ...billLists: (BillDetailed | BillSummary)[][]
): BillDetailed[] => {
  const uniqueBillMap = new Map();
  billLists.forEach((bills) =>
    bills.forEach((bill) => {
      uniqueBillMap.set(bill.id, bill);
    }),
  );
  return sortBillByAlpha(Array.from(uniqueBillMap.values()), false);
};

export const convertIdToAbbreviatedTitle = (billId: string): string => {
  const idData = billId.split('-');
  const billNumberRegex = /^(?<prefix>[A-Z]+)(?<number>\d+)$/;
  const billNumberSegment = idData[idData.length - 1].toUpperCase();
  const billNumberMatch = billNumberRegex.exec(billNumberSegment);

  // For weird bills that don't match the regex, return what is likely the bill number
  if (!billNumberMatch || !billNumberMatch.groups) return billNumberSegment;
  const { prefix, number } = billNumberMatch.groups;
  switch (idData[0]) {
    case 'federal':
      return `FED ${prefix.replace(/(S|H|J|CON|R(?!ES)|RES)/g, '$1.')} ${number}`;
    case 'state':
      return `${idData[1].toUpperCase()} ${prefix} ${number}`;
    default:
      return billNumberSegment;
  }
};

export const convertIdToJurisdictionAbbreviation = (billId: string): string => {
  const idData = billId.split('-');
  const billNumberRegex = /^(?<prefix>[A-Z]+)(?<number>\d+)$/;

  const billNumberMatch = billNumberRegex.exec(idData[idData.length - 1].toUpperCase());
  if (!billNumberMatch || !billNumberMatch.groups) return '';
  switch (idData[0]) {
    case 'federal':
      return `federal`;
    case 'state':
      return `${idData[1].toUpperCase()}`;
    default:
      return '';
  }
};

export const userTeamIsTracking = (
  bill?: BillSummary | BillDetailed,
  orgUser?: OrganizationUser,
): boolean => {
  if (!orgUser || !bill) {
    return false;
  }
  const orgUserTeams = orgUser.teamMemberships.map((team) => team.teamId);
  return !!bill.teams?.find((team) => orgUserTeams.includes(team.id));
};

export const getMostRecentAction = (bill: BillSummary): BillAction | undefined => {
  const sortedActions = bill.actions ? [...bill.actions].sort(sortActions) : [];
  return sortedActions[0];
};

export const renderMostRecentActionDate = (bill: BillSummary): string => {
  const action = getMostRecentAction(bill);
  return action ? renderBillActionDate(action) : '';
};

// "long" format looks like January 25, 2024
// "short" format looks like 01/25/2024
export const renderBillActionDate = (
  action: BillAction,
  format?: 'long' | 'short',
): string => {
  const longFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const shortFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const dateObj = new Date(action.actionDate);
  const formatObj = format === 'short' ? shortFormat : longFormat;

  // Most actions need to be corrected from "Midnight UTC" to display the correct date.
  // See bill-action-timings.md
  if (dateObj.getMinutes() === 0 && dateObj.getSeconds() === 0) {
    formatObj.timeZone = 'utc';
  }

  return new Date(action.actionDate).toLocaleDateString('en-US', formatObj);
};

const sortActions = (a: BillAction, b: BillAction): number => {
  const dateDiff = moment.utc(b.actionDate).diff(moment.utc(a.actionDate));
  if (dateDiff === 0) {
    return b.order - a.order;
  }
  return dateDiff;
};

export const filterBillsByJurisdiction = (
  bills: Bill[],
  selectedJurisdictions: string[],
): Bill[] => {
  return selectedJurisdictions.length === 0
    ? bills
    : bills.filter((bill) => {
        const jur = bill.session.state.abbreviation;
        return selectedJurisdictions.includes(jur);
      });
};

export const filterBillsByTags = (
  bills: Bill[],
  selectedTags: string[],
  advancedFilterOption?: AdvancedFilterOption,
): Bill[] => {
  if (selectedTags.length > 0) {
    switch (advancedFilterOption) {
      case 'any':
        return bills.filter((bill) =>
          bill.tags.some((tag) => selectedTags.includes(tag.id.toString())),
        );
      case 'all':
        return bills.filter((bill) =>
          selectedTags.every((tagId) =>
            bill.tags.some((tag) => tag.id.toString() === tagId),
          ),
        );
      case 'none':
        return bills.filter((bill) =>
          bill.tags.every((tag) => !selectedTags.includes(tag.id.toString())),
        );
      default:
        return bills.filter((bill) =>
          bill.tags.some((tag) => selectedTags.includes(tag.id.toString())),
        );
    }
  }
  return bills;
};

export const filterBillsByStatus = (bills: Bill[], statusFilters: string[]): Bill[] => {
  if (statusFilters && statusFilters.length > 0) {
    return bills.filter((bill) => bill.status && statusFilters.includes(bill.status));
  }
  return bills;
};

export const translateBillStatusForBadge = (billStatus: BillStatus): string => {
  const map = {
    [BillStatus.LAW]: 'Law',
    [BillStatus.GOVERNOR]: 'Signed',
    [BillStatus.INTRODUCED]: 'Introduced',
    [BillStatus.PASSED]: 'Passed',
    [BillStatus.PASSED_LOWER]: 'Passed Lower',
    [BillStatus.PASSED_UPPER]: 'Passed Upper',
    [BillStatus.FIRST_READING]: 'First Reading',
    [BillStatus.SECOND_READING]: 'Second Reading',
    [BillStatus.THIRD_READING]: 'Third Reading',
    [BillStatus.REFERRAL_COMMITTEE]: 'Referred to Committee',
    [BillStatus.REPORTED_OUT_OF_COMMITTEE]: 'Reported out of Committee',
    [BillStatus.CONCURRENCE]: 'Sent for Concurrence',
    [BillStatus.WITHDRAWAL]: 'Withdrawn',
    [BillStatus.VETOED]: 'Vetoed',
  };

  if (map[billStatus]) {
    return map[billStatus];
  } else throw new Error(`Bill status ${status} not found in map`);
};

export const filterBillsByPartySponsors = (
  bills: Bill[],
  sponsorFilters: string[],
): Bill[] => {
  let filteredBills = bills;
  if (sponsorFilters && sponsorFilters.length > 0) {
    if (sponsorFilters.includes('democrat')) {
      filteredBills = filteredBills.filter(
        (bill) =>
          bill.sponsors &&
          bill.sponsors.some((s) => partyString(s) === democratPartyIdentifier),
      );
    }
    if (sponsorFilters.includes('republican')) {
      filteredBills = filteredBills.filter(
        (bill) =>
          bill.sponsors &&
          bill.sponsors.some((s) => partyString(s) === republicanPartyIdentifier),
      );
    }
  }
  return filteredBills;
};

export const filterBillsByBillActions = (
  bills: Bill[],
  actionFilter: SearchActionFilter | undefined,
): Bill[] => {
  let filteredBills = bills;
  if (actionFilter) {
    if (actionFilter.type === SearchActionFilterType.FIRST_ACTION) {
      filteredBills = filteredBills.filter((bill) => {
        if (!bill.actions || bill.actions.length === 0) {
          return false;
        }
        const firstAction = bill.actions.reduce((a, b) => {
          return a.actionDate < b.actionDate ? a : b;
        });
        const firstActionDate = new Date(firstAction.actionDate);
        if (
          firstActionDate >= actionFilter.fromDate &&
          firstActionDate <= actionFilter.toDate
        )
          return true;
        return false;
      });
    }
    if (actionFilter.type === SearchActionFilterType.LATEST_ACTION) {
      filteredBills = filteredBills.filter((bill) => {
        if (!bill.actions || bill.actions.length === 0) {
          return false;
        }
        const lastAction = bill.actions.reduce((a, b) => {
          return a.actionDate > b.actionDate ? a : b;
        });
        const lastActionDate = new Date(lastAction.actionDate);
        if (
          lastActionDate >= actionFilter.fromDate &&
          lastActionDate <= actionFilter.toDate
        )
          return true;
        return false;
      });
    }
    if (actionFilter.type === SearchActionFilterType.ANY_ACTION) {
      filteredBills = filteredBills.filter((bill) =>
        bill.actions.some(
          (action) =>
            new Date(action.actionDate) >= actionFilter.fromDate &&
            new Date(action.actionDate) <= actionFilter.toDate,
        ),
      );
    }
    if (actionFilter.type === SearchActionFilterType.INTRODUCTION) {
      filteredBills = filteredBills.filter((bill) =>
        bill.actions.some(
          (action) =>
            new Date(action.actionDate) >= actionFilter.fromDate &&
            new Date(action.actionDate) <= actionFilter.toDate &&
            action.classifications?.includes('introduction'),
        ),
      );
    }
    if (actionFilter.type === SearchActionFilterType.PASSAGE) {
      filteredBills = filteredBills.filter((bill) =>
        bill.actions.some(
          (action) =>
            new Date(action.actionDate) >= actionFilter.fromDate &&
            new Date(action.actionDate) <= actionFilter.toDate &&
            action.classifications?.includes('passage'),
        ),
      );
    }
    if (actionFilter.type === SearchActionFilterType.AMENDMENT) {
      filteredBills = filteredBills.filter((bill) =>
        bill.actions.some(
          (action) =>
            new Date(action.actionDate) >= actionFilter.fromDate &&
            new Date(action.actionDate) <= actionFilter.toDate &&
            action.classifications?.some((c) => c.startsWith('amendment-')),
        ),
      );
    }
  }
  return filteredBills;
};
