import React, { ReactElement } from 'react';
import { JurisdictionAPI } from '../../api';

interface AnalyzerJurisdictionPickerProps {
  selectedJurisdiction: string;
  setSelectedJurisdiction: (selected: string) => void;
  onClick: (e: React.SyntheticEvent) => void;
}

const AnalyzerJurisdictionPicker = (
  props: AnalyzerJurisdictionPickerProps,
): ReactElement => {
  const { selectedJurisdiction, setSelectedJurisdiction, onClick } = props;

  const { data: allJurisdictions, isFetching } =
    JurisdictionAPI.endpoints.getJurisdictions.useQuery();

  const jurisdictionOptions = ['Jurisdiction'].concat(
    allJurisdictions?.map((j) => j.abbreviation.toUpperCase()) || [],
  );

  return (
    <select
      defaultValue={selectedJurisdiction || 'Jurisdiction'}
      onChange={(e) => setSelectedJurisdiction(e.target.value)}
      // Slight hack to prevent the dropdown expanding for a split-second before
      // onClick fires (probably to redirect a logged-out user
      onMouseDown={onClick}
    >
      {isFetching ? (
        <option>Loading...</option>
      ) : (
        jurisdictionOptions?.map((j, index) => (
          <option key={`juris-${j}-${index}`}>{j}</option>
        ))
      )}
    </select>
  );
};

export default AnalyzerJurisdictionPicker;
