import React from 'react';
import JurisdictionAdapter from './Adapter';

class GAAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^Introduction|^Resolution introduced/;
    this.textStyleRules = {
      headers: [
        [/^A BILL TO BE ENTITLED$|^AN ACT$/],
        [/^(SECTION \d+(-\d+)?\.|Section \d+(-\d+)?\.(?![0-9])|Sec\. \d+\.(?![0-9]))/],
        [/^BE IT ENACTED BY/],
      ],
      listItems: [
        [/^\([a-h]+\)/],
        [/^\([0-9.]+\)|\([a-z]+\)\([0-9]+\)/],
        [/^\([A-Z]+\)/],
        [/^\([ivx]+\)/],
      ],
      inline: [
        {
          regex:
            /^(SECTION \d+(-\d+)?\.|SEC\. \d+\.|Section \d+(-\d+)?\.(?![0-9])|Sec\. \d+\.(?![0-9]))/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-16" key={key}>
              {node}
            </strong>
          ),
        },
        {
          regex: /^(\s*["]*\([a-zA-Z]\)|\s*["]*\([0-9]+\))(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => <strong key={key}>{node}</strong>,
        },
        {
          regex:
            /^(WHEREAS,|NOW, THEREFORE, BE IT RESOLVED BY THE SENATE|NOW, THEREFORE, BE IT RESOLVED BY THE HOUSE OF REPRESENTATIVES|BE IT FURTHER RESOLVED)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <span className="inline-section-text-14" key={key}>
              {node}
            </span>
          ),
        },
      ],
    };
  }

  static sortBillVersions(versions) {
    const keywords = [
      { word: 'auditor', sort: -5 },
      { word: 'prefile', sort: 0 },
      { word: ' ad', sort: 1 },
      { word: 'introduced', sort: 2 },
      { word: 'committee', sort: 5 },
      { word: 'passed', sort: 8 },
      { word: 'signed', sort: 10 },
    ];

    const versionSortMap = {};
    versions.forEach((version) => {
      // baseline
      versionSortMap[`${version.id}`] = 1;
      keywords.forEach((keyword) => {
        if (version.versionName.toLowerCase().indexOf(keyword.word) > -1) {
          versionSortMap[`${version.id}`] = keyword.sort;
        }
      });
    });

    return [...versions].sort((a, b) => {
      if (versionSortMap[`${a.id}`] < versionSortMap[`${b.id}`]) {
        return -1;
      }
      if (versionSortMap[`${a.id}`] > versionSortMap[`${b.id}`]) {
        return 1;
      }
      return 0;
    });
  }
}

export default GAAdapter;
