import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../static/plural-policy-analyzer.svg';
import { State } from '../../dux/@types';
import { useSelector } from 'react-redux';
import { logout } from '../../dux';
import { useDispatch } from 'react-redux';

const AnalyzerTopBar = (): ReactElement => {
  const authToken = useSelector((state: State) => state.authentication.authToken);
  const dispatch = useDispatch();

  return (
    <aside className="top-nav">
      <Link to="/analyzer/">
        <img src={logo} />
      </Link>
      <span>
        {!authToken ? (
          <Link className="button" to="/analyzer/login">
            Log in
          </Link>
        ) : (
          <a
            className="button"
            href="#"
            onClick={() => {
              dispatch(logout());
            }}
          >
            Log Out
          </a>
        )}
        <Link className="button dark-orange" to="/analyzer/login">
          Sign Up
        </Link>
      </span>
    </aside>
  );
};

export default AnalyzerTopBar;
