import React, { ReactElement } from 'react';
import icon from '../../static/plural-icon.svg';
import closeIcon from '../../static/x.svg';
import { Link } from 'react-router-dom';
import AnalyzerTopBar from './AnalyzerTopBar';
const AnalyzerLogInOverlay = (): ReactElement => {
  return (
    <>
      <AnalyzerTopBar />
      <main className="login-overlay">
        <div className="login-card-wrapper">
          <img className="close-icon" src={closeIcon} />
          <div className="login-card">
            <img src={icon} />
            <h2>Subscription Required</h2>
            <p>
              This feature requires an active subscription. You can set up a
              subscription on the <Link to="/analyzer/settings">Settings Page</Link>.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default AnalyzerLogInOverlay;
