import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

interface MatchParams {
  billId: string;
}

const LandingPageControls = (): ReactElement => {
  // bill ID from route params used to highlight selected demonstration bill, if any
  const match = useRouteMatch<MatchParams>('/analyzer/demonstration/:billId');
  const billId = match && match?.params ? match.params.billId : null;

  return (
    <>
      <div className="landing-above-fold">
        <h1>Instantly Analyze Bills with AI</h1>
        <span className="tagline">Better understand legislation in seconds.</span>
        <div className="quick-summary">
          <span className="quick-summary-explanation">
            ✨ Try it out! Choose a sample bill to analyze:
          </span>
          <div className="quick-summary-buttons">
            {[
              {
                jurisdictionAbbreviation: 'Texas',
                billNumber: 'HB 1709',
                billId: 'state-tx-89r-hb1709',
                title: 'Texas Responsible Artificial Intelligence Governance Act',
              },
              {
                jurisdictionAbbreviation: 'California',
                billNumber: 'SB 11',
                billId: 'state-ca-20252026-sb11',
                title: 'Artificial Intelligence Technology',
              },
              {
                jurisdictionAbbreviation: 'New York',
                billNumber: 'A 222',
                billId: 'state-ny-2025_2026-a222',
                title: 'Chatbot Information Liability',
              },
            ].map((b, index) => (
              <Link
                className={billId && billId === b.billId ? 'selected' : ''}
                key={`quick-summary-${index}`}
                to={`/analyzer/demonstration/${b.billId}`}
              >
                <strong>
                  {b.jurisdictionAbbreviation} {b.billNumber}
                </strong>{' '}
                {b.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageControls;
