import React, { useState, ReactElement } from 'react';
import AnalyzerJurisdictionPicker from './AnalyzerJurisdictionPicker';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../dux/@types';

interface AnalyzerBillSearchProps {
  searchButtonText: string;
}

interface AnalyzerBillSearchUrlParams {
  jurisdictionAbbr: string;
  searchText: string;
}

const AnalyzerBillSearch = (props: AnalyzerBillSearchProps): ReactElement => {
  const { searchButtonText } = props;
  const params = useParams<AnalyzerBillSearchUrlParams>();
  const [searchText, setSearchText] = useState(params?.searchText || '');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(
    params?.jurisdictionAbbr || '',
  );
  const authToken = useSelector((state: State) => state.authentication.authToken);

  const history = useHistory();

  /* Particularly on the landing page, we want any interaction with the search controls to redirect to the
   sign-up funnel */
  const redirectLoggedOutUsers = (e: React.SyntheticEvent): void => {
    if (!authToken) {
      history.push('/analyzer/login');
      e.preventDefault();
    }
  };

  const handleSearch = (): void => {
    if (authToken) {
      history.push(`/analyzer/search/${selectedJurisdiction}/${searchText}`);
    } else {
      history.push('/analyzer/login');
    }
  };

  return (
    <div className="search-controls">
      <AnalyzerJurisdictionPicker
        onClick={redirectLoggedOutUsers}
        selectedJurisdiction={selectedJurisdiction}
        setSelectedJurisdiction={setSelectedJurisdiction}
      />
      <input
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onClick={redirectLoggedOutUsers}
        placeholder="Enter a bill number"
        type="text"
        value={searchText}
      />
      <a className="button dark-orange" href="#" onClick={() => handleSearch()}>
        <span className="sparkles">✨</span> {searchButtonText}
      </a>
    </div>
  );
};

export default AnalyzerBillSearch;
