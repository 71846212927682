import React from 'react';
import JurisdictionAdapter from './Adapter';

class LAAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /Introduced in the|Read by title/;
    this.textStyleRules = {
      headers: [
        [
          /^AN ACT|^A RESOLUTION|^ARTICLE \d+|^A CONCURRENT RESOLUTION|^A STUDY REQUEST|^A JOINT RESOLUTION|^CONFERENCE COMMITTEE REPORT|^A CONCURRENT STUDY REQUEST/,
        ],
        [/^Be it enacted by the Legislature of Louisiana/i],
        [],
      ],
      listItems: [[/^[A-Z]+\./], [/^"?\([0-9]+\)/]],
      inline: [
        {
          regex: /^(Section \d+\.?[A-Za-z]?\.)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-16" key={key}>
              {node}
            </strong>
          ),
        },
        {
          regex: /^(WHEREAS,|BE IT FURTHER RESOLVED|THEREFORE, BE IT RESOLVED)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <span className="inline-section-text-14" key={key}>
              {node}
            </span>
          ),
        },
        {
          regex: /^("?\([0-9]+\)|[A-Z]+\.(\([0-9]+\))*)(.*)/,
          nonMatchingGroup: 3,
          wrap: (node, key) => <strong key={key}>{node}</strong>,
        },
      ],
    };
  }
}

export default LAAdapter;
