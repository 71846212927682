import React, { ReactElement, useState } from 'react';
import { BillAutoSummaryResponse } from '@enview/interface/types/dataInsightsAPI/BillAutoSummary';
import './AnalyzerLoader.scss';
import { t } from 'i18next';
import AnalyzerLoadingAnimation from './AnalyzerLoadingAnimation';
import { trackBillAutoSummaryFeedback } from '../../analytics/BillAnalytics';
import { BillDetailed } from '@enview/interface/types/bills/Bill';

interface AiSummaryDisplayProps {
  bill: BillDetailed;
  introducedVersionId: number;
  aiSummaryData: BillAutoSummaryResponse | undefined;
  aiSummaryDataFetching: boolean;
  aiSummaryDataError: boolean;
}

const AiSummaryDisplay = (props: AiSummaryDisplayProps): ReactElement => {
  const {
    bill,
    introducedVersionId,
    aiSummaryData,
    aiSummaryDataError,
    aiSummaryDataFetching,
  } = props;

  const [summarySent, setSummarySent] = useState(false);

  const trackFeedback = (answer: boolean): void => {
    trackBillAutoSummaryFeedback(
      bill.id,
      introducedVersionId,
      answer,
      aiSummaryData?.summary || '(missing)',
      'Analyzer',
    );
    setSummarySent(true);
  };

  return (
    <div className="ai-summary">
      <div className="content-box disclaimer">
        AI can make mistakes. Consider validating important information.
      </div>

      {aiSummaryDataFetching && (
        <AnalyzerLoadingAnimation message={'Loading AI-generated summary'} />
      )}

      {aiSummaryDataError && <div>Error loading AI Summary...</div>}
      {!aiSummaryDataFetching && !aiSummaryDataError && aiSummaryData && (
        <div className="content-box ai-summary-content">
          {aiSummaryData.insightsApiError
            ? t(aiSummaryData.errorResourceCode)
            : aiSummaryData.summary}
        </div>
      )}
      {!summarySent && (
        <div className="feedback">
          Help us improve. Was this summary accurate?{' '}
          <a
            className="green-text"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              trackFeedback(true);
            }}
          >
            Yes
          </a>{' '}
          or{' '}
          <a
            className="red-text"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              trackFeedback(false);
            }}
          >
            No
          </a>
        </div>
      )}
      {summarySent && <div className="feedback">Thank you for your feedback!</div>}
    </div>
  );
};

export default AiSummaryDisplay;
