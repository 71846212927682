import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTextSelection } from './billTextSelectionHook';
import { BillAPI } from '../../../api';

type BillTextPhraseSearchPeekProps = {
  billTextRef: React.RefObject<HTMLElement>;
};

const BillTextPhraseSearchPeek = (
  props: BillTextPhraseSearchPeekProps,
): ReactElement => {
  // state
  const billTextRefCurrent =
    props.billTextRef.current === null ? undefined : props.billTextRef.current;
  const { clientRect, isCollapsed, textContent } = useTextSelection(billTextRefCurrent);
  const selectionIsCollapsed = isCollapsed;

  // search
  const params = useMemo(
    () => ({
      criteria: {
        jurisdictions: ['mn'],
        sessionNames: ['2021-2022 Regular Session'],
        query: `"${textContent}"`,
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
    }),
    [textContent],
  );
  const { data: results, isSuccess } = BillAPI.endpoints.searchBills.useQuery(params);

  // styles
  const absolute = 'absolute' as const;
  const style = {
    position: absolute,
    opacity: selectionIsCollapsed === false ? 1 : 0,
    zIndex: selectionIsCollapsed === false ? 1000 : -1000,
    top: clientRect ? clientRect.top : 0, // V HACKY!
    left: clientRect ? clientRect.left : 0, // V HACKY!
    color: '#f68c24',
    width: '400px',
    borderColor: '#000',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: '#fff',
  };
  return (
    <>
      {results && isSuccess && results.data && results.data.length > 0 && (
        <div className="search-phrase-peek" style={style}>
          <ul>
            {results.data.map((bill) => (
              <li key={bill.id}>
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  to={`/legislative-tracking/bill/details/${bill.id}`}
                >
                  {bill.number} {bill.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default BillTextPhraseSearchPeek;
