import React, { ReactElement, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { StripeAPI } from '../../api';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const AnalyzerCheckout = (): ReactElement => {
  const [createCheckoutSession, { data }] =
    StripeAPI.endpoints.createCheckoutSession.useMutation();

  // Kind of an annoying construction, but I think this is necessary to
  // have a mutation fire when the component loads. The client secret necessary
  // to initiate the checkout form comes back as the "data" variable above
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await createCheckoutSession();
    };
    void fetchData();
  }, []);

  return (
    <div id="checkout">
      {data && (
        <EmbeddedCheckoutProvider
          options={{ clientSecret: data }}
          stripe={stripePromise}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default AnalyzerCheckout;
