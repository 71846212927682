import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  ReactElement,
  ReactPortal,
} from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { getTeamMode } from '../../../../dux';
import { createEditor, Descendant, Editor, Transforms, Range } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { BillAPI, TeamAPI } from '../../../../api';
import { TeamMembership } from '@enview/interface/types/Team';
import isHotkey from 'is-hotkey';
import {
  ContainerNode,
  RichTextNodeType,
  TextNode,
  Attributes,
} from '@enview/interface/types/actions/UserAction';
import {
  Element,
  EditorButton,
  BlockButton,
  GenerateButton,
  toggleMark,
  getSummary,
} from './RichTextEditorComponents';
import './RichTextEditor.scss';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold } from '@fortawesome/free-solid-svg-icons';
import ItalicLight from '../../../../components/svg/italic-light';
import UnderlineLight from '../../../../components/svg/underline-light';
import PaperclipVerticalLight from '../../../../components/svg/paperclip-vertical-light';
import ListOlLight from '../../../../components/svg/list-ol-light';
import ListUlSharpLight from '../../../../components/svg/list-ul-sharp-light';
import UserIcon from '../../Icons/UserIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { t } from 'i18next';
import { checkPermission } from '../../../../dux';
import { useDispatch } from 'react-redux';
import { g as abilityGlossary } from '../../../../config/ability';

const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const PLACEHOLDER_TEXT = 'Add a note. Type @ to mention a teammate...';
const LOGGED_OUT_PLACEHOLDER = 'To leave a note, please Log In or Create an Account.';

const Portal = (props: { children: ReactElement }): ReactPortal | null => {
  const { children } = props;
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const withMentions = (editor: Editor): Editor => {
  const { isInline, isVoid, markableVoid } = editor;

  // isInline: mention node can now be a sibling with Text nodes
  // isVoid: check for if value is a void Element object
  // markableVoid: can choose to apply Marks to void element's text children

  editor.isInline = (element) => {
    return element.hasOwnProperty('type') &&
      (element as ContainerNode).type === 'mention'
      ? true
      : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.hasOwnProperty('type') &&
      (element as ContainerNode).type === 'mention'
      ? true
      : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return (
      (element.hasOwnProperty('type') &&
        (element as ContainerNode).type === 'mention') ||
      markableVoid(element)
    );
  };

  return editor;
};

// applying custom formatting through Leaf nodes/child nodes
const Leaf = (props: {
  attributes: Attributes;
  children: ReactElement;
  leaf: TextNode;
}): ReactElement => {
  const { attributes, leaf } = props;
  let { children } = props;
  if (leaf.bold) {
    children = <span style={{ fontWeight: 900 }}>{children}</span>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

type RichTextEditorProps = {
  aiSummary?: boolean;
  userAction?: Partial<UserAction>;
  onChangeToEditor: (event: any) => void;
  reset?: boolean;
  setReset?: (arg: boolean) => void;
  selectUserFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  billId?: string;
  billVersionId?: number;
  placeholder?: string;
};

const RichTextEditor = (props: RichTextEditorProps): ReactElement => {
  const {
    onChangeToEditor,
    userAction,
    reset,
    setReset,
    selectUserFile,
    disabled,
    billId,
    billVersionId,
    aiSummary,
    placeholder,
  } = props;
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonPress, setButtonPress] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const canViewSummary = () => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.BILL_SUMMARY, true),
    );
  };

  const teamId = useSelector(getTeamMode);
  const errorMessage = <p>An error occurred. Please try again later.</p>;
  const memberListItem = (member: TeamMembership) => (
    <div className="menu-item">
      <div className="user-icon">
        <UserIcon
          firstName={member.firstName || ''}
          lastName={member.lastName || ''}
          size={30}
        />
      </div>
      <div>
        <p className="mention-name">
          {member.firstName} {member.lastName}
        </p>
        <p className="mention-email">{member.email}</p>
      </div>
    </div>
  );

  const { data: teamMembers, isError } =
    TeamAPI.endpoints.getTeamMembers.useQuery(teamId);

  const { data: summaryResponse, isLoading } =
    BillAPI.endpoints.getBillAutoSummary.useQuery(
      billId && billVersionId && aiSummary && canViewSummary()
        ? {
            billId: billId,
            billVersionId: billVersionId,
          }
        : skipToken,
    );

  const teamMemberFilter =
    !isError &&
    teamMembers &&
    search &&
    teamMembers
      .filter((name) =>
        `${name.firstName} ${name.lastName}`
          .toLowerCase()
          .startsWith(search.toLowerCase()),
      )
      .slice(0, 10);

  const insertMention = (
    editor: Editor,
    character: string,
    organizationUserId: number,
  ): void => {
    const mention: ContainerNode = {
      type: RichTextNodeType.MENTION,
      character,
      organizationUserId,
      children: [{ text: character }],
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
  };

  const editor = useMemo(
    // withHistory instantiates editor with undo and redo capabilities
    // withReact adds React and DOM specific behaviors to the editor
    () => withMentions(withReact(withHistory(createEditor()))),
    [],
  );

  //   when text level formatting is rendered, characters are grouped into
  // leaves of text that have the same formatting applied to them
  const renderLeaf = useCallback((LeafProps) => <Leaf {...LeafProps} />, []);
  const renderElement = useCallback(
    (ElementProps) => <Element {...ElementProps} />,
    [],
  );

  const initialValue: Descendant[] = [
    {
      type: RichTextNodeType.PARAGRAPH,
      children: [{ text: '' }],
    },
  ];

  const getInitialValue = (): Descendant[] => {
    if (userAction?.commentJson) {
      return (userAction.commentJson as ContainerNode).children;
    } else if (userAction?.comment) {
      return [
        {
          type: RichTextNodeType.PARAGRAPH,
          children: [{ text: userAction.comment }],
        },
      ];
    } else {
      return initialValue;
    }
  };

  useEffect(() => {
    if (target && ref.current && teamMemberFilter && teamMemberFilter.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [teamMemberFilter && teamMemberFilter?.length, editor, index, search, target]);

  useEffect(() => {
    if (reset === true && setReset) {
      const point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };
      editor.history = { redos: [], undos: [] };
      editor.children = initialValue;
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (buttonPress) {
      getSummary(editor, summaryResponse?.summary, setLoading);
      setButtonPress(false);
    }
  }, [summaryResponse]);

  // set the correct target to insert mentions
  const selectTarget = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);

      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(undefined);
  };

  const getPlaceHolderText = () => {
    if (placeholder) {
      return placeholder;
    } else if (disabled) {
      return LOGGED_OUT_PLACEHOLDER;
    }
    return PLACEHOLDER_TEXT;
  };

  return (
    <>
      {/* render the Slate context provider here */}
      {/* must be rendered above any <Editable> components */}
      <Slate
        editor={editor}
        onChange={(value) => {
          onChangeToEditor(value);
          selectTarget();
        }}
        value={getInitialValue()}
      >
        <div className="btn-group toolbar">
          <EditorButton
            disabled={disabled}
            format="bold"
            icon={<FontAwesomeIcon icon={faBold} />}
          />
          <EditorButton disabled={disabled} format="italic" icon={<ItalicLight />} />
          <EditorButton
            disabled={disabled}
            format="underline"
            icon={<UnderlineLight />}
          />
          <div className="vertical-bar" />
          <BlockButton
            disabled={disabled}
            format="bulleted-list"
            icon={<ListUlSharpLight />}
          />
          <BlockButton
            disabled={disabled}
            format="numbered-list"
            icon={<ListOlLight />}
          />
          {selectUserFile && (
            <>
              <div className="vertical-bar" />
              <input
                disabled={disabled}
                id="user-file"
                name="user-file"
                onChange={selectUserFile}
                type="file"
              />
              <label
                className="rich-text-button"
                htmlFor="user-file"
                style={{ alignSelf: 'center', padding: '5px' }}
              >
                <PaperclipVerticalLight
                  style={{ margin: 'auto', verticalAlign: '-0.125em' }}
                />
              </label>
            </>
          )}
        </div>
        {aiSummary && (
          <GenerateButton
            billId={billId}
            icon={<div>{t('bill.sidebar.billSummary.buttonText.generateDetail')}</div>}
            loading={loading}
            setButtonPress={setButtonPress}
            setLoading={setLoading}
            summary={summaryResponse ? summaryResponse.summary : isLoading}
          />
        )}
        {/* Editable component is like contenteditable */}
        {/* anywhere you render it will render an editable richtext document */}
        <Editable
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          placeholder={getPlaceHolderText()}
          readOnly={disabled}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          style={{ padding: '24px 24px 12px' }}
        />
        {target && teamMemberFilter && teamMemberFilter.length > 0 && (
          <Portal>
            <div className="mentions-dropdown" data-cy="mentions-portal" ref={ref}>
              {teamMemberFilter?.slice(0, 5).map((member) => (
                // each name in the list is a clickable div
                <div
                  key={`${member.email}`}
                  onClick={() => {
                    Transforms.select(editor, target);
                    if (member.firstName && member.lastName && member.email) {
                      insertMention(
                        editor,
                        `${member.firstName} ${member.lastName}`,
                        member.organizationUserId,
                      );
                    }
                    setTarget(undefined);
                  }}
                  role="menuitem"
                >
                  {isError ? errorMessage : memberListItem(member)}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
    </>
  );
};

export default RichTextEditor;
