import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BillTextLineContent extends Component {
  constructor(props) {
    super(props);

    this.wrapperCount = 0;
  }

  /**
   * Get the text content of a react node (including children)
   * @param child
   * @return {string}
   */
  getText(child) {
    if (Array.isArray(child)) {
      return child.map((grandChild) => this.getText(grandChild)).join('');
    }

    if (typeof child === 'string') {
      return child;
    }

    if (typeof child.props.children === 'string') {
      return child.props.children;
    }

    if (child && child.props && child.props.children && child.props.children.map) {
      return child.props.children.map((grandChild) => this.getText(grandChild)).join('');
    }
    return '';
  }

  /**
   * Wrap a react node using a styleRule
   * @param styleRule
   * @param node
   * @return {*}
   */
  wrapJsx(styleRule, node) {
    this.wrapperCount += 1;
    return styleRule.wrap(node, this.wrapperCount);
  }

  /**
   * Find if a react node is just an array of text strings
   * @param child
   * @return {boolean}
   */
  isArrayOfText(child) {
    if (typeof child !== 'object' || !child.filter) {
      return false;
    }
    return child.filter((grandchild) => typeof grandchild !== 'string').length === 0;
  }

  /**
   * Apply style rule to an array of react nodes, injecting inline JSX modifications
   * Currently text is matched from left to right. Preceeding text is always included.
   * @param styleRule
   * @param precedingText
   * @param children
   * @return {Array}
   */
  applyStyleRule(styleRule, precedingText, children) {
    let bufferText = precedingText;
    let bufferJsx = [];
    const newJsx = [];

    // make sure children is actually an array of JSX, not literal text
    const childrenToProcess = children && children.forEach ? children : [children];

    childrenToProcess.forEach((child) => {
      // bufferText starts with precedingText, gets each child's text appended until match found
      const currentText = this.getText(child);
      const match = `${bufferText}${currentText}`.match(styleRule.regex);
      if (match) {
        // Current text + preceding text matches the rule!
        // WRAP and SHIP any existing buffer jsx
        // (right now we assume rules match text starting at the beginning of the line)
        // TODO: support separating previous buffer into preceding (nonmatch) and matching segments
        if (bufferJsx.length > 0) {
          newJsx.push(this.wrapJsx(styleRule, bufferJsx));
          bufferJsx = [];
        }

        // Process this child and any children it might have
        if (React.isValidElement(child)) {
          // Child is a react element, so we need to wrap it and process children
          const styledChildren = this.applyStyleRule(styleRule, bufferText, child.props.children);
          newJsx.push(React.cloneElement(child, {}, styledChildren));
        } else if (Array.isArray(child)) {
          // Child is an array, so we need to recurse to loop through it
          newJsx.push(this.applyStyleRule(styleRule, bufferText, child));
        } else {
          // Child is text, let's split up and ship the matching/non-matching parts
          const nonMatchingGroup = match[styleRule.nonMatchingGroup];
          if (nonMatchingGroup && nonMatchingGroup.length > 0) {
            const matchingTextFragment = currentText.slice(
              0,
              currentText.length - nonMatchingGroup.length
            );
            const nonMatchingTextFragment = currentText.slice(
              currentText.length - nonMatchingGroup.length
            );

            // WRAP and SHIP the matching
            bufferJsx.push(matchingTextFragment);
            newJsx.push(this.wrapJsx(styleRule, bufferJsx));
            bufferJsx = [];

            // SHIP the non-matching rest of the text
            newJsx.push(nonMatchingTextFragment);
          } else {
            // WRAP and SHIP the matching text
            bufferJsx.push(this.wrapJsx(styleRule, child));
            newJsx.push(bufferJsx);
            bufferJsx = [];
          }
        }

        // Clear buffer text, since we just processed a match
        // (assume match is complete, do not want to wrap following text)
        bufferText = '';
      } else {
        // SAVE to buffer, either to get matched with additional nodes or just SHIPPED later
        bufferJsx.push(child);
        bufferText += currentText;
      }
    });

    // SHIP any buffer left over after all the processing
    if (bufferJsx.length > 0) {
      newJsx.push(bufferJsx);
    }

    return newJsx;
  }

  render() {
    let jsx = this.props.children;
    this.props.styleRules.forEach((styleRule) => {
      try {
        // doing a try here because the fallback (just display the line content as-is)
        // is much better than throwing a JS error and failing to render the page
        jsx = this.applyStyleRule(styleRule, '', jsx);
      } catch (e) {
        // console.error(e);
      }
    });
    this.wrapperCount = 0;
    return jsx;
  }
}

BillTextLineContent.propTypes = {
  styleRules: PropTypes.array,
  children: PropTypes.array,
};

export default BillTextLineContent;
