/* eslint-disable no-cond-assign */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BillAnnotation as Annotation } from '@enview/interface/types/bills/BillAnnotation';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import BillTextLine from '../scenes/BillView/BillText/BillTextLine';
import BillTextLineContent from '../components/BillTextLineContent';
import BillAnnotationTooltip from '../scenes/BillView/BillText/BillAnnotationTooltip';
import JurisdictionAdapter from './jurisdictions/Adapter';
import { State } from '../dux/@types';
import { UserActionAPI } from '../api';
import { getTeamMode } from '../dux/TeamModeDux';

type AnnotationCoordinates = { line: number; page: number };

/**
 * Temporarily normalize line number
 * Use for functions that heavily rely on it to be number
 * Fractional part of line number are padded with leading zeroes
 * to remove any form of ambuiguity
 * 0.4    = 0.0004
 * 0.40   = 0.0040
 * 0.400  = 0.0400
 * 0.4000 = 0.4000
 * @param {number|string} lineNumber
 * @return {number}}
 */
const getNormalizedLineNumber = (lineNumber: string): number => {
  const number = `${lineNumber}`;
  // Get the number of digits after floating point
  const floatPointPos = number.indexOf('.');
  //   Return if it is an integer
  if (floatPointPos < 0) return Number(lineNumber);
  // Pad fractional part with leading zeroes (such that as 0.1 different from 0.10) i.e.
  const wholeDigits = number.slice(0, floatPointPos);
  const floatDigits = number.slice(floatPointPos + 1);
  const paddedFloatDigits = floatDigits.padStart(4, '0');
  return parseFloat(`${wholeDigits}.${paddedFloatDigits}`);
};

/**
 * Get the starting or ending page/line number from an Annotation
 * @param {Annotation} annotation
 * @param {string} which Either 'from' or 'to'
 * @return {*|{line: number, page: number}}
 */
const getAnnotationCoords = (
  annotation: Annotation,
  which: string,
): AnnotationCoordinates => {
  const coords = which === 'from' ? annotation.fromLine : annotation.toLine;
  return {
    page: parseFloat(coords.split('-')[0]),
    line: getNormalizedLineNumber(coords.split('-')[1]),
  };
};

/**
 * Sorter func to list annotations from top-left to bottom-right in text content
 * @param {Annotation} annoActionA
 * @param {Annotation} annoActionB
 * @return {number}
 */
const sortAnnotationsLeftToRightTopToBottom = (
  annoA: Annotation,
  annoB: Annotation,
): number => {
  const startA = getAnnotationCoords(annoA, 'from');
  const startB = getAnnotationCoords(annoB, 'from');
  if (
    startA.page < startB.page ||
    (startA.page === startB.page && startA.line < startB.line) ||
    (startA.page === startB.page &&
      startA.line === startB.line &&
      annoA.fromCharacter < annoB.fromCharacter)
  ) {
    return -1;
  }
  if (
    startA.page === startB.page &&
    startA.line === startB.line &&
    annoA.fromCharacter === annoB.fromCharacter
  ) {
    return 0;
  }
  return 1;
};

/**
 * Create a filter function that gets annotations that match a give page + line
 * @param {number} page Page number
 * @param {number} line Line number
 * @return {function(*=): boolean}
 */
const annotationsForLineFilterFactory =
  (page: number, line: number) => (annotationAction: UserAction) => {
    const { annotation } = annotationAction;
    if (!annotation) return false;
    const annoFrom = getAnnotationCoords(annotation, 'from');
    const annoTo = getAnnotationCoords(annotation, 'to');

    return (
      annotation.fromCharacter >= 0 &&
      annotation.fromCharacter !== null &&
      annotation.toCharacter >= 0 &&
      annotation.toCharacter !== null &&
      (annoFrom.page < page || (annoFrom.page === page && annoFrom.line <= line)) &&
      (annoTo.page > page || (annoTo.page === page && annoTo.line >= line))
    );
  };

/**
 * Make a React element key from page, line and textIndex #
 * @param {string} page Page number
 * @param {number} line Line number
 * @param {number} textIndex Index position within text
 * @return {string}
 */
const makeKey = (page: string, line: number, textIndex: number): string =>
  `${page}-${line}-${textIndex}`;

/**
 * Parse a text segment of CEML, inserting Annotations
 * NOTE: assumes that annotations do not overlap. Bugs probably if they overlap!
 * @param {string} text The text segment to be parsed
 * @param {number} textIndex Cursor: starting position of this text segment within entire line's text
 * @param {number} page Page number
 * @param {line} line Line number
 * @param {Array} annotationActions Array of UserActions with annotations that are in or overlap this line, sorted left to right
 * @return {Array}
 */
const parseText = (
  text: string,
  textIndex: number,
  page: string,
  line: number,
  annotationActions: UserAction[],
): string[] => {
  const jsx = [];
  // we'll advance current Index as we process annotations
  let currentIndex = textIndex;
  const pageNumber = parseInt(page, 10);

  annotationActions.forEach((action) => {
    const annotation: Annotation = action.annotation!;
    const annoFrom = getAnnotationCoords(annotation, 'from');
    const annoTo = getAnnotationCoords(annotation, 'to');
    // console.log('anno from/to chars', annotation.fromCharacter, annotation.toCharacter);

    // annoStartIndex and annoEndIndex are relative to the *entire* text of the line, not this segment
    // but we adjust the start/end if the annotations starts on a previous line (or ends on a subsequent line)
    const annoStartIndex =
      annoFrom.page < pageNumber || annoFrom.line < line ? 0 : annotation.fromCharacter;
    const annoEndIndex =
      annoTo.page > pageNumber || annoTo.line > line
        ? text.length + textIndex
        : annotation.toCharacter;

    // therefore use textIndex as an offset to correct annoStartIndex/EndIndex to get the annotated part of this text
    const annoText = text.slice(
      Math.max(0, annoStartIndex - textIndex),
      Math.max(0, annoEndIndex - textIndex),
    );
    // console.log('slice values, text', Math.max(0, annoStartIndex - textIndex), Math.max(0, annoEndIndex - textIndex), text);

    // console.log('anno text:', annoText);
    if (annoText.length > 0) {
      // console.log('currentIndex/annoStartIndex', currentIndex, annoStartIndex);
      // Is there any text in this text segment that precedes the annotated part of the segment? Push it to JSX
      const precedingText = text.slice(
        currentIndex - textIndex,
        Math.max(currentIndex - textIndex, annoStartIndex - textIndex),
      );
      if (precedingText.length > 0) {
        // console.log('preceding text is', precedingText);
        jsx.push(precedingText);
      }

      jsx.push(
        <BillAnnotationTooltip annotation={action} key={action.id}>
          {annoText}
        </BillAnnotationTooltip>,
      );

      // Move the currentIndex cursor forward, so we start at that part of the text segment for next annotation
      currentIndex += precedingText.length + annoText.length;
    }
  });
  if (currentIndex - textIndex < text.length) {
    // add rest of text to the jsx array, if any remains
    jsx.push(text.slice(currentIndex - textIndex));
  }
  return jsx;
};

/**
 * Parse a LINE element's CEML content
 * @param {string} page Formatted page string
 * @param {number} line Line number
 * @param {string} lineContent CEML content of a LINE
 * @param {Array} annotationActions Array of UserActions with Annotations
 * @param {number} startingTextIndex
 * @return {Array}
 */
const parseLineHTMLToJSX = (
  page: string,
  line: number,
  lineContent: string,
  annotationActions: UserAction[],
  startingTextIndex = 0,
): (string[] | JSX.Element)[] => {
  const lineContentJsx = [];

  const insDelRegex = /^<(ins|del)>([\s\S]*?)<\/(ins|del)>/i;
  const linkRegex = /^<a .*?href="(.+?)".*?>([\s\S]*?)<\/a>/i;
  const textRegex = /^([\s\S]+?)(<\/?(ins|del|a)|$)/i;
  const insRegex = /ins/i;

  let lineIndex = 0;
  let textIndex = startingTextIndex;
  while (lineIndex < lineContent.length) {
    // Get the part of the line we're working on
    const remainingLine = lineContent.slice(lineIndex);

    // Check if there is text
    let match;
    if ((match = linkRegex.exec(remainingLine)) !== null) {
      lineContentJsx.push(
        <a
          href={match[1]}
          key={makeKey(page, line, textIndex)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {parseLineHTMLToJSX(page, line, match[2], annotationActions, textIndex)}
        </a>,
      );
      textIndex += match[2].length;
      lineIndex += match[0].length;
    } else if ((match = insDelRegex.exec(remainingLine)) !== null) {
      if (insRegex.test(match[1])) {
        lineContentJsx.push(
          <ins key={makeKey(page, line, textIndex)}>
            {parseLineHTMLToJSX(page, line, match[2], annotationActions, textIndex)}
          </ins>,
        );
      } else {
        lineContentJsx.push(
          <del key={makeKey(page, line, textIndex)}>
            {parseLineHTMLToJSX(page, line, match[2], annotationActions, textIndex)}
          </del>,
        );
      }
      textIndex += match[2].length;
      lineIndex += match[0].length;
    } else if ((match = textRegex.exec(remainingLine)) !== null) {
      lineContentJsx.push(
        parseText(match[1], textIndex, page, line, annotationActions),
      );
      textIndex += match[1].length;
      lineIndex += match[1].length;
    } else {
      console.error(
        `Unrecognized section of CEML could not be parsed: ${remainingLine}`,
      );
      lineIndex += remainingLine.length;
    }
  }

  return lineContentJsx;
};

/**
 * Parse CEML to JSX, including any provided annotations at the right spots
 * @param {string} ceml CEML content to parse
 * @param {Array} annotationActions Array of UserActions with annotations
 * @param {JurisdictionAdapter} jurisdictionAdapter Optional, generates style classes for a line
 * @param {[id: string]: RefObject<HTMLDivElement>} refs to allow jump to text
 * @return {Array}
 */
export default (
  billId: string,
  ceml: string,
  jurisdictionAdapter: JurisdictionAdapter,
  billVersionId?: number,
): JSX.Element[] => {
  const jsx = [];
  const lineRegex = /<line(.*?)>([\s\S]*?)<\/line>/g;
  const pageRegex = /page=["']([0-9]*)["']/;
  const lineNumberRegex = /number=["']([0-9]+\.*[0-9]*)["']/;
  const classRegex = /class=["'](.*?)["']/;

  const refs = useSelector((state: State) => state.billView.lineRefs);
  const teamModeId = useSelector(getTeamMode);

  const params = useMemo(
    () => ({
      teamId: teamModeId,
      includeBillDetails: false,
      billId,
    }),
    [teamModeId, billId],
  );
  const { data: userActions } = UserActionAPI.endpoints.getUserActions.useQuery(params);
  const annotationActions = (userActions || []).filter((ua) => ua.annotation);

  let match;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let lastPage;
  let syntheticLineNumber = 1;
  while ((match = lineRegex.exec(ceml)) !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [wholeLine, lineAttributes, content] = match;

    // Parse LINE attributes
    const pageAttrMatch = pageRegex.exec(lineAttributes);
    const page = pageAttrMatch ? pageAttrMatch[1] : '';
    const lineNumberAttrMatch = lineNumberRegex.exec(lineAttributes);
    const number = lineNumberAttrMatch ? lineNumberAttrMatch[1] : '';
    const classAttrMatch = classRegex.exec(lineAttributes);
    let styleClasses = classAttrMatch ? classAttrMatch[1].split(' ') : [];

    const lineNumber = number === '' ? `${syntheticLineNumber}` : number;
    const pageNumber = page === '' ? 0 : parseInt(page, 10);
    const normalizedLineNumber = getNormalizedLineNumber(lineNumber);

    if (jurisdictionAdapter && jurisdictionAdapter.lineStyleGenerator) {
      styleClasses = jurisdictionAdapter.lineStyleGenerator(content, styleClasses);
    }
    const annotationsForLine = annotationActions
      .filter((action) => action.billData?.billVersionId === billVersionId)
      .filter(annotationsForLineFilterFactory(pageNumber, normalizedLineNumber))
      .sort((a, b) => {
        return sortAnnotationsLeftToRightTopToBottom(a.annotation!, b.annotation!);
      });

    const parsedLineContent = parseLineHTMLToJSX(
      page,
      normalizedLineNumber,
      content,
      annotationsForLine,
      0,
    );

    const inlineStyleRules =
      jurisdictionAdapter &&
      jurisdictionAdapter.textStyleRules &&
      jurisdictionAdapter.textStyleRules.inline
        ? jurisdictionAdapter.textStyleRules.inline
        : [];
    const key = `${page}-${lineNumber}`;
    let ref = null;
    if (annotationsForLine.length > 0) {
      ref = refs ? refs[key] : undefined;
    }
    jsx.push(
      <BillTextLine
        displayNumber={!number.includes('.') && number !== ''}
        key={key}
        line={lineNumber}
        page={page}
        ref={ref}
        styleClasses={styleClasses.join(' ')}
      >
        <BillTextLineContent styleRules={inlineStyleRules}>
          {parsedLineContent}
        </BillTextLineContent>
      </BillTextLine>,
    );
    syntheticLineNumber += 1;
  }

  return jsx;
};
