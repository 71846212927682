import React from 'react';
import JurisdictionAdapter from './Adapter';

class USAAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^Held at the desk|^Filed with Secretary/;
    this.textStyleRules = {
      headers: [
        [
          /^A BILL|^A Bill|^CONCURRENT RESOLUTION|^Concurrent Resolution|^JOINT RESOLUTION|^Joint Resolution|^AN ACT|^An Act|^AMENDMENT|^Amendment/,
        ],
        [],
        [/^WHEREAS,|^RESOLVED,/],
      ],
      listItems: [[/^[‘‘]*\([a-zA-Z]+\)/], [/^[‘‘]*\([0-9]+\)/]],
      inline: [
        {
          regex:
            /^(\s*[‘‘]*SECTION [0-9]+\.|[0-9]+\.+[^0-9.]|\s*[‘‘]*SEC\. [0-9]+\.|[0-9]+\.+[^0-9.]) (.+)?/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-14" key={key}>
              {node}
            </strong>
          ),
        },
        {
          regex: /^(\s*[‘‘]*\([a-zA-Z]\)|\s*[‘‘]*\([0-9]+\))(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => <strong key={key}>{node}</strong>,
        },
        {
          regex: /^(WHEREAS,|BE IT FURTHER RESOLVED|THEREFORE, BE IT RESOLVED)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <span className="inline-section-text-14" key={key}>
              {node}
            </span>
          ),
        },
      ],
    };
  }
}

export default USAAdapter;
