import React, { ReactElement, useState } from 'react';
import './DemonstrationSummary.scss';
import BillText from '../BillView/BillText/BillText';
import AiSummaryDisplay from './AiSummaryDisplay';
import { BillDetailed } from '@enview/interface/types/bills/Bill';
import { BillAutoSummaryResponse } from '@enview/interface/types/dataInsightsAPI/BillAutoSummary';

interface AnalyzerBillSummaryProps {
  bill: BillDetailed;
  aiSummaryData: BillAutoSummaryResponse | undefined;
  aiSummaryDataFetching: boolean;
  aiSummaryDataError: boolean;
  introducedVersionId: number;
}

const AnalyzerBillSummary = (props: AnalyzerBillSummaryProps): ReactElement => {
  const {
    bill,
    aiSummaryData,
    aiSummaryDataError,
    aiSummaryDataFetching,
    introducedVersionId,
  } = props;
  const [selectedTab, setSelectedTab] = useState('summary-tab-selector');
  const [selectedVersionId, setSelectedVersionId] = useState(
    bill?.versions.at(0)?.id || -1,
  );

  const selectedVersion = bill.versions.find((v) => v.id === selectedVersionId);

  return (
    <>
      <div className="number-and-controls">
        <h2>
          {`${bill.session.state.name} ${bill.number}`} <br /> <span>{bill.name}</span>
        </h2>
        <span className="bill-metadata">
          <span className="pill dark-green session">{bill.session.displayName}</span>
          <span className="pill dark-green bill-status">
            {bill?.status || 'Introduced'}
          </span>
          <span>
            {' '}
            Bill Version:
            <select
              onChange={(evt) => {
                const value = parseInt(evt.target.value);
                if (!isNaN(value)) setSelectedVersionId(value);
              }}
              value={selectedVersionId}
            >
              {bill.versions.map((bv, index) => (
                <option key={`${bv.id}-${index}`} value={bv.id}>
                  {bv.versionName}
                </option>
              ))}
            </select>
          </span>
        </span>
      </div>
      <div className="bill-title">{bill.name}</div>
      <div className="tab-links">
        <a
          className={
            selectedTab === 'summary-tab-selector'
              ? 'button dark-orange'
              : 'button light'
          }
          href="#"
          id="summary-tab-selector"
          onClick={(e) => {
            e.preventDefault();
            setSelectedTab('summary-tab-selector');
          }}
        >
          AI Bill Analysis
        </a>
        <a
          className={
            selectedTab === 'text-tab-selector' ? 'button dark-orange' : 'button light'
          }
          href="#"
          id="text-tab-selector"
          onClick={(e) => {
            e.preventDefault();
            setSelectedTab('text-tab-selector');
          }}
        >
          Bill Text
        </a>
      </div>
      <div className="tab-content">
        {selectedTab === 'summary-tab-selector' && (
          <AiSummaryDisplay
            aiSummaryData={aiSummaryData}
            aiSummaryDataError={aiSummaryDataError}
            aiSummaryDataFetching={aiSummaryDataFetching}
            bill={bill}
            introducedVersionId={introducedVersionId}
          />
        )}
        {selectedTab === 'text-tab-selector' && selectedVersion && (
          <>
            {' '}
            {selectedVersion.diffText ? (
              <BillText
                annotationsEnabled={false}
                bill={bill}
                billVersionId={selectedVersion.id}
                debug={false}
                diff={selectedVersion.diffText ?? ''}
                showChanges={true}
              />
            ) : (
              <div>
                Plural was unable to embed this bill's text from the source. View this
                bill on its{' '}
                <a
                  href={selectedVersion.sourceDocUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  original website
                </a>{' '}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AnalyzerBillSummary;
