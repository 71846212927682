import LegislativeEventsSearchParams from '@enview/interface/events/LegislativeEventsSearchParams';
import { SearchResult } from '@enview/interface/types/BillSearch';
import { JurisdictionReferenceInfo } from '@enview/interface/types/JurisdictionReferenceInfo';
import { LegislativeSessionLegacyEndpoint } from '@enview/interface/src/types/LegislativeSession';
import { Hearing } from '@enview/interface/types/bills/Bill';
import base, { ApiTag } from './base';
import { Jurisdiction } from '@enview/interface/types/Jurisdiction';

export const CURRENT_SESSION_FILTER = 'Current Sessions';

const jurisdictionAPI = base.injectEndpoints({
  endpoints: (build) => ({
    // Returns the full list of US jurisdictions - does not require auth
    getJurisdictions: build.query<Jurisdiction[], void>({
      query: () => ({
        url: `/jurisdictions`,
        method: 'GET',
      }),
      providesTags: [{ type: ApiTag.DEFAULT_US_JURISDICTIONS }],
    }),
    getSessions: build.query<LegislativeSessionLegacyEndpoint[], string[]>({
      query: (params) => {
        const jurisdictionParams = params.map(
          (jurisdiction) => `jurisdictionAbbreviations=${jurisdiction.toLowerCase()}`,
        );
        return {
          url: `/legislative-sessions?${jurisdictionParams.join('&')}`,
          method: 'GET',
        };
      },
    }),
    getReferenceInfo: build.query<JurisdictionReferenceInfo, string>({
      query: (jurisAbbr) => {
        const juris = jurisAbbr.toLowerCase();
        return {
          url: `/jurisdictions/${juris}/reference-info`,
          method: 'GET',
        };
      },
    }),
    getEvents: build.query<
      SearchResult<Hearing>,
      { jurisAbbr: string; params: LegislativeEventsSearchParams }
    >({
      query: (search) => {
        const jurisAbbr = search.jurisAbbr.toLowerCase();
        return {
          url: `v2/jurisdictions/${jurisAbbr}/events?${convertParamsToQuery(
            search.params,
          )}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

const convertParamsToQuery = (params: LegislativeEventsSearchParams): string => {
  const paramsToConvert = { ...params, committeeIds: null };
  const urlParams = Object.entries(paramsToConvert)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      const isDate = value instanceof Date;
      const formattedValue = isDate ? value.toISOString() : String(value);
      return `${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`;
    });

  return urlParams.join('&');
};

export default jurisdictionAPI;
