import React, { ReactElement } from 'react';
import logo from '../../static/plural-policy-analyzer.svg';
import './Analyzer.scss';
import { Link } from 'react-router-dom';
import arrow from '../../static/arrow-circle-broken-right.svg';

import { logout } from '../../dux';

import { useDispatch } from 'react-redux';

const AnalyzerLoggedInLayout = (props: { children: ReactElement }): ReactElement => {
  const dispatch = useDispatch();
  const { children } = props;
  return (
    <div className="fixed-layout-inner-scroll">
      <div className="fixed-height-content">
        <aside className="left-nav">
          <Link to="/analyzer/summary/">
            <img className="logo" src={logo} />
          </Link>
          <div className="sidebar-content">
            <h2>Better public policy intelligence.</h2>
            <a
              className="callout-button"
              href="https://pluralpolicy.com/bill-tracking-intelligence-price/"
              target="_blank"
            >
              Tools for <br />
              Organizations & Teams
              <img className="link-arrow" src={arrow} />
            </a>
            <div className="links">
              <Link to="/analyzer/search">Search Bills</Link>
              <Link to="/analyzer/settings">Account Settings</Link>
              <a
                href="#"
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Log Out
              </a>
            </div>
          </div>
        </aside>
        <main>{children}</main>
      </div>
      <footer>
        <span>© Plural Policy, Inc.</span>
      </footer>
    </div>
  );
};

export default AnalyzerLoggedInLayout;
