import React, { ReactElement, useEffect } from 'react';
import './Analyzer.scss';
import { Route, Switch } from 'react-router-dom';
import AnalyzerLandingPageLayout from './AnalyzerLandingPageLayout';
import AnalyzerLoggedInLayout from './AnalyzerLoggedInLayout';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
import AnalyzerLogInOverlay from './AnalyzerLogInOverlay';
import AnalyzerSearchAndSummaryPage from './AnalyzerSearchAndSummaryPage';
import AnalyzerAccountSettings from './AnalyzerAccountSettings';
import { getOrganizationUser } from '../../dux/AccountDux';
import AnalyzerCheckout from './AnalyzerCheckout';
import AnalyzerCheckoutReturn from './AnalyzerCheckoutReturn';
import AnalyzerSubscriptionOverlay from './AnalyzerSubscriptionOverlay';
import { StripeAPI } from '../../api';
const AnalyzerRootLayout = (): ReactElement => {
  const authToken = useSelector((state: State) => state.authentication.authToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(getOrganizationUser());
    }
  }, [dispatch, authToken]);

  const { data: userSubscription } =
    StripeAPI.endpoints.getSubscriptionForUser.useQuery();

  const requireLogin = (component: ReactElement): ReactElement => {
    if (authToken) {
      return <AnalyzerLoggedInLayout>{component}</AnalyzerLoggedInLayout>;
    }
    return <AnalyzerLogInOverlay />;
  };

  const requireSubscription = (component: ReactElement): ReactElement => {
    if (userSubscription && userSubscription.status === 'active') {
      return component;
    }
    return <AnalyzerSubscriptionOverlay />;
  };

  // Terrible hack to hide the intercom controls from showing up on any analyzer page.
  // Maybe there's a way to selectively hide this within the Intercom app?
  const styleHack = `
    .intercom-lightweight-app {
        display: none;
    }
`;
  return (
    <>
      <style>{styleHack}</style>
      <div className="analyzer">
        <Switch>
          <Route path="/analyzer/summary/:billId?">
            {requireLogin(requireSubscription(<AnalyzerSearchAndSummaryPage />))}
          </Route>
          <Route path="/analyzer/settings">
            {requireLogin(<AnalyzerAccountSettings />)}
          </Route>
          <Route path="/analyzer/checkout">{requireLogin(<AnalyzerCheckout />)}</Route>
          <Route path="/analyzer/return/:checkoutSessionId">
            {requireLogin(<AnalyzerCheckoutReturn />)}
          </Route>
          <Route path="/analyzer/login">
            <AnalyzerLogInOverlay />
          </Route>
          <Route path="/analyzer">
            <AnalyzerLandingPageLayout />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default AnalyzerRootLayout;
