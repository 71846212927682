import StripeCheckoutSession from '@enview/interface/types/stripe/StripeCheckoutSession';
import StripeSubscription from '@enview/interface/types/stripe/StripeSubscription';
import base, { ApiTag } from './base';
// wrapper for the stripe-based operations in the Plural API backend - we use the stripe-js
// library for cases where we need to interact with the Stripe API directly

const stripeAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getCheckoutSession: build.query<StripeCheckoutSession, string>({
      query: (checkoutSessionId: string) => ({
        url: `/v2/stripe/checkout-session/${checkoutSessionId}`,
        method: 'GET',
      }),
    }),
    createCheckoutSession: build.mutation<string, void>({
      query: () => ({ url: `/v2/stripe/checkout-session`, method: 'POST' }),
      transformResponse: (response: { client_secret: string }) =>
        response.client_secret,
    }),
    updateSubscriptionDetails: build.mutation<StripeSubscription, string>({
      query: (subscriptionId) => ({
        url: `/v2/stripe/subscription/${subscriptionId}/update`,
        method: 'POST',
      }),
      invalidatesTags: (result) => [
        { type: ApiTag.USER_STRIPE_SUBSCRIPTION, id: result?.organizationUserId },
      ],
    }),
    cancelSubscription: build.mutation<StripeSubscription, string>({
      query: (subscriptionId) => ({
        url: `/v2/stripe/subscription/${subscriptionId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: (result) => [
        { type: ApiTag.USER_STRIPE_SUBSCRIPTION, id: result?.organizationUserId },
      ],
    }),
    getSubscriptionForUser: build.query<StripeSubscription, void>({
      query: () => ({
        url: `/v2/stripe/subscription`,
        method: 'GET',
      }),
      providesTags: (result) => [
        { type: ApiTag.USER_STRIPE_SUBSCRIPTION, id: result?.organizationUserId },
      ],
    }),
  }),
  overrideExisting: false,
});

export default stripeAPI;
