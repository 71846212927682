import React from 'react';
import JurisdictionAdapter from './Adapter';

class MIAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^INTRODUCED BY/;
    this.textStyleRules = {
      headers: [
        [/^HOUSE BILL No\.|^SENATE BILL No\./],
        [/^A bill to amend|^CHAPTER [A-Z.]/],
        [/^THE PEOPLE OF THE STATE OF MICHIGAN/],
      ],
      listItems: [
        [/^\([a-z]+\)/],
        [/^\([0-9]+\)/],
        [/^\([A-Z]+\)/],
        [/^\((i|ii|iii|iv|v|vi|vii|viii|ix|x)\)/],
      ],
      inline: [
        {
          regex: /^(Sec|SEC)\. \d+[A-Za-z]?\.+([^0-9.].+)?/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-16" key={key}>
              {node}
            </strong>
          ),
        },
      ],
    };
  }
}

export default MIAdapter;
