import React from 'react';
import JurisdictionAdapter from './Adapter';

const subsectionLabelStyle = {
  fontSize: '14pt',
};

class CAAdapter extends JurisdictionAdapter {
  constructor() {
    super();
    this.actionClassifiers.introduced = /^Introduced/;
    this.textStyleRules = {
      headers: [],
      listItems: [[/^\(\d\)/], [/^\([a-zA-Z]\)/]],
      inline: [
        // section headings
        {
          regex: /^((SECTION|SEC.)\s\d+\.)(.*)/,
          nonMatchingGroup: 3,
          wrap: (node, key) => (
            <strong key={key} style={subsectionLabelStyle}>
              {node}
            </strong>
          ),
        },
        // subsection labels
        {
          regex: /^(\d+(\.|$))+(?!$|\d+)(.*)/,
          nonMatchingGroup: 3,
          wrap: (node, key) => <strong key={key}>{node}</strong>,
        },
      ],
    };
  }
}

export default CAAdapter;
