import { connect } from 'react-redux';
import BillAnnotationFromSelection from '../components/BillAnnotationFromSelection2';
import { checkPermission, openEditUserActionModal } from '../dux';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckPermission: (action, subject) => {
      return dispatch(checkPermission(action, subject));
    },
    openEditUserActionModal: (userAction) => {
      dispatch(openEditUserActionModal(userAction));
    },
  };
};

const BillAnnotationFromSelectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillAnnotationFromSelection);

export default BillAnnotationFromSelectionContainer;
