import React, { ReactElement } from 'react';
import AnalyzerBillSearch from './AnalyzerBillSearch';
import AnalyzerBillSummary from './AnalyzerBillSummary';

const AnalyzerSearchAndSummaryPage = (): ReactElement => {
  return (
    <>
      <div className="header-search-controls">
        <h3>Bill Search</h3>
        <AnalyzerBillSearch searchButtonText="Analyze" />
      </div>
      <div className="main-content">
        <AnalyzerBillSummary />
      </div>
    </>
  );
};

export default AnalyzerSearchAndSummaryPage;
