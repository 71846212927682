import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import rangy from 'rangy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { g as abilityGlossary } from '../config/ability';
import { withTranslation, Trans } from 'react-i18next';
import GateTooltip, { GateTooltipFeature } from '../scenes/Shared/Tooltips/TooltipGate';

class BillAnnotationFromSelection extends Component {
  constructor(props) {
    super(props);

    this.annotationBoundaries = null;
    this.annotationsIsEditable = true;
    this.canCreateAnnotation = this.props.onCheckPermission(
      abilityGlossary.CREATE,
      abilityGlossary.BILL_ANNOTATION,
    );

    this.debouncedHandleSelectionChange = debounce(30, this.handleSelectionChange);

    this.state = {
      annotationLinkVisible: false,
      annotationLinkTopPos: 0,
      annotationLinkLeftPos: 0,
      showTooltipGate: false,
    };
  }

  componentDidMount() {
    document.addEventListener('selectionchange', this.debouncedHandleSelectionChange);
    this.rootNode = document.getElementById('billText');
  }

  componentWillUnmount() {
    document.removeEventListener(
      'selectionchange',
      this.debouncedHandleSelectionChange,
    );
  }

  showAnnotationLink = (position) => {
    this.setState({
      annotationLinkVisible: true,
      annotationLinkTopPos: position.top,
      annotationLinkLeftPos: position.left,
    });
  };

  hideAnnotationLink = () => {
    if (this.state.annotationLinkVisible && !this.state.showTooltipGate) {
      this.setState({
        annotationLinkVisible: false,
      });
    }
  };

  editAnnotation = () =>
    this.annotationBoundaries
      ? {
          annotation: {
            fromLine: `${this.annotationBoundaries.startLine}`, // API requires this be a string
            fromCharacter: this.annotationBoundaries.startOffset,
            toLine: `${this.annotationBoundaries.endLine}`, // API requires this be a string
            toCharacter: this.annotationBoundaries.endOffset,
            selectedText: this.annotationBoundaries.selectedText,
          },
          billData:
            this.props.bill &&
            this.props.bill.versions &&
            this.props.bill.versions.length > 0
              ? {
                  billId: this.props.bill.id,
                  billVersionId: this.props.billVersionId,
                }
              : undefined,
        }
      : undefined;

  addAnnotation = (event) => {
    if (!this.range) return;
    if (this.canCreateAnnotation) {
      this.annotationBoundaries = this.findBoundaries();
      this.setState({
        annotationLinkVisible: false,
      });
      this.props.openEditUserActionModal(this.editAnnotation());
    } else {
      this.setState({ showTooltipGate: true }, () => {
        setTimeout(() => {
          this.setState({ annotationLinkVisible: false });
        }, 700);
      });
    }
  };

  handleSelectionChange = (event) => {
    this.getSelection();

    if (
      !this.range ||
      !this.annotationsIsEditable ||
      this.doesSelectedTextContainExistingAnnotation()
    ) {
      this.hideAnnotationLink();
    } else if (!!this.range && this.annotationsIsEditable) {
      const parentIds = BillAnnotationFromSelection.getParentIds(
        this.range.commonAncestorContainer,
      );
      if (parentIds.includes('billText')) {
        const position = this.getLinkPosition(this.range);
        this.showAnnotationLink(position);
      }
    }
  };

  static getParentIds(node) {
    const parents = [];
    while (node) {
      if (node.id) {
        parents.unshift(node.id);
      }
      node = node.parentNode;
    }

    return parents;
  }

  getLinkPosition = (element) => {
    const domElement = document.getElementById('billText').getBoundingClientRect();
    const position = element.getBoundingClientRect();
    return {
      top: position.bottom - domElement.top + 24 /* padding */,
      left: position.left - domElement.left + position.width / 2,
    };
  };

  getSelection = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) {
      return;
    }
    this.selection = selection;
    const range = selection.getRangeAt(0);
    this.range = range.collapsed ? null : range;
  };

  getSelectionText = () => {
    let text = '';
    if (!this.selection) {
      this.getSelection();
    }
    if (this.selection) {
      text = this.selection.toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      text = document.selection.createRange().text;
    }
    return text;
  };

  /**
   * Identify how much to adjust a position within text, based on map of deletions in that text
   * @param pos
   * @param deltaMap
   * @returns {*}
   */
  sumDeletionDelta = (pos, deltaMap) => {
    return deltaMap.reduce((sum, delta) => {
      return delta.pos < pos ? sum + delta.length : sum;
    }, 0);
  };

  getPageAndLineNumberFromParentLine = (domNode) => {
    const parent = domNode.parentElement.closest('div.bill-text-line');
    if (!parent) {
      console.error('no parent bill line found for node', domNode);
    }

    return {
      page: parent.dataset.page,
      number: parent.dataset.number,
    };
  };

  /**
   * Find the DOM element that is the closest previous sibling (up and to the left!)
   * within a .line-content element.
   * @param {Element} elem
   * @return {null|Node}
   */
  findPreviousSiblingWithinLine = (elem) => {
    if (elem.previousSibling) {
      return elem.previousSibling;
    }
    if (
      elem.parentElement &&
      (!elem.classList || !elem.classList.contains('line-content'))
    ) {
      return this.findPreviousSiblingWithinLine(elem.parentElement);
    }
    // there is no parent, or the parent is the .line-content element
    return null;
  };

  /**
   * Is the DOM element within the actual text component of the bill line?
   * @param element
   * @return {boolean|*}
   */
  isWithinLineTextContent = (element) => {
    if (element.classList && element.classList.contains('line-content')) {
      return true;
    }
    if (element.classList && element.classList.contains('bill-text-line')) {
      return false;
    }
    if (!element.parentElement) {
      return false;
    }
    return this.isWithinLineTextContent(element.parentElement);
  };

  /**
   * Is the selected text within an existing annotation?
   * @return {boolean}
   */
  doesSelectedTextContainExistingAnnotation = () => {
    // Does selection start/end within annotation
    const startNode = this.range.startContainer.parentNode;
    const endNode = this.range.endContainer.parentNode;

    // If selection spans multiple lines, commonAncestorContainer is the entire bill text
    // So manually search range's document fragment for annotation class
    return (
      (startNode.classList && startNode.classList.contains('annotation-highlight')) ||
      (endNode.classList && endNode.classList.contains('annotation-highlight')) ||
      !!this.range.cloneContents().querySelector('.annotation-highlight')
    );
  };

  getTextOffsetOfTextContainerWithinLine = (startingOffset, startingContainer) => {
    let offset = startingOffset;
    if (!this.isWithinLineTextContent(startingContainer)) {
      // selection ends *outside* the content of a line of the bill, so offset is 0
      return 0;
    }

    let prevElem = this.findPreviousSiblingWithinLine(startingContainer);
    while (prevElem) {
      // if the prev sibling was an Element or a Text node, then add its text length to the offset
      if (prevElem.nodeType === 1 || prevElem.nodeType === 3) {
        offset += prevElem.textContent.length;
      } else {
      }
      prevElem = this.findPreviousSiblingWithinLine(prevElem);
    }
    return offset;
  };

  findBoundaries = () => {
    // fix selected text to exclude line numbers
    // get the text of all the div.line-content in the selection
    let textSelection = this.range.startContainer.textContent.slice(
      this.range.startOffset,
      this.range.endOffset,
    );
    if (
      this.range.commonAncestorContainer.nodeType !==
      this.range.commonAncestorContainer.TEXT_NODE
    ) {
      // OK the selection encompasses more than just one node
      const rangySelection = rangy.getSelection();
      const rangyRange = rangySelection.getRangeAt(0);
      const rangyNodes = rangyRange.getNodes(
        [3],
        (node) =>
          !node.parentElement.classList ||
          !node.parentElement.classList.contains('line-number'),
      );
      const selectionTexts = rangyNodes.map((node) => node.textContent);
      selectionTexts[0] = selectionTexts[0].slice(this.range.startOffset);
      selectionTexts[selectionTexts.length - 1] = selectionTexts[
        selectionTexts.length - 1
      ].slice(0, this.range.endOffset);
      textSelection = selectionTexts.join(' ');

      // we're just joining all texts by ' ' as a hack to account for newlines (which aren't represented in the array)
      // so let's just kill any spaces greater than one
      textSelection = textSelection.replace(/'\s+'/g, ' ');
    }

    // Get the page/line coordinates for the starting and ending lines that were selected
    const startingPageAndLine = this.getPageAndLineNumberFromParentLine(
      this.range.startContainer,
    );
    const endingPageAndLine = this.getPageAndLineNumberFromParentLine(
      this.range.endContainer,
    );

    // Get the proper text offset within the text content of the starting and ending lines
    const startOffset = this.getTextOffsetOfTextContainerWithinLine(
      this.range.startOffset,
      this.range.startContainer,
    );
    const endOffset = this.getTextOffsetOfTextContainerWithinLine(
      this.range.endOffset,
      this.range.endContainer,
    );

    return {
      startLine: `${startingPageAndLine.page}-${startingPageAndLine.number}`,
      startOffset,
      endLine: `${endingPageAndLine.page}-${endingPageAndLine.number}`,
      endOffset,
      selectedText: textSelection,
    };
  };

  static getLineNumberMatchingStringIndex(str, index, baseZero) {
    return str.substring(0, index).split('\n').length - (baseZero ? 1 : 0);
  }

  render() {
    const { showTooltipGate } = this.state;
    const { t } = this.props;

    const annotationLinkVisible = {
      opacity: this.state.annotationLinkVisible ? 1 : 0,
      zIndex: this.state.annotationLinkVisible ? 1000 : -1000,
      top: this.state.annotationLinkTopPos,
      left: this.state.annotationLinkLeftPos,
      color: '#f68c24',
      height: '48px',
      width: '64px',
    };

    return (
      <>
        <div>
          <div
            id="addAnnotation"
            className="fa-stack fa-3x"
            style={annotationLinkVisible}
          >
            <FontAwesomeIcon
              icon={faCommentAlt}
              className="fa-stack-1x"
              size="1x"
              onMouseDown={this.addAnnotation}
              flip="vertical"
            >
              +
            </FontAwesomeIcon>
            <FontAwesomeIcon
              icon={faAlignLeft}
              className="fa-stack-2x pb-2 comment-bubble"
              size="2x"
              flip="vertical"
              onMouseDown={this.addAnnotation}
            >
              +
            </FontAwesomeIcon>
          </div>
        </div>
        {!this.canCreateAnnotation && showTooltipGate && (
          <GateTooltip
            accountType="pro"
            anchorId={'addAnnotation'}
            customContent={
              <Trans
                components={{
                  tagLink: <a href={t('urls.annotatingBills')} target="_blank" />,
                  gate: <span className="tooltip-header" />,
                }}
                i18nKey="featureGating.tooltipText.annotateBills"
                key="featureGating.tooltipText.annotateBills"
              />
            }
            featureName={GateTooltipFeature.BillAlias}
            place="top"
          />
        )}
      </>
    );
  }
}

BillAnnotationFromSelection.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userSelectedTeams: PropTypes.array,
  onCheckPermission: PropTypes.func.isRequired,
  bill: PropTypes.object.isRequired,
  billVersionId: PropTypes.number,
};

export default withTranslation()(BillAnnotationFromSelection);
