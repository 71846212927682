/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-case-declarations */
import BillSearchView from '@enview/interface/types/bills/BillSearchView';
import { Jurisdiction } from '@enview/interface/types/Jurisdiction';
import {
  EmailNotifications,
  OrganizationUser,
  UserSettings,
} from '@enview/interface/types/OrganizationUser';
import assign from 'lodash-es/assign';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';
import { getTeamMembers, logout, setAuthErrorMessage } from '..';
import * as Analytics from '../../analytics/AccountAnalytics';
import instance from '../../config/axiosConfig';
import { createAuthErrorMessage } from '../../helpers/AuthHelper';
import { AccountOnboardingUserData, AccountSetupData } from '../../models/Onboarding';
import { PagerMeta } from '../../models/PagerMeta';
import { ProfileSettingsSection } from '../../scenes/AccountView/ProfileSettings';
import { OnboardingStage } from '../../scenes/Registration/RegistrationHelper';
import { Action, State, Thunk } from '../@types';
import {
  requestError,
  requestFailure,
  requestSent,
  requestSucceeded,
  requestSuccess,
  resetRequest,
} from '../RequestDux';
import {
  AccountState,
  REMOVE_ORGANIZATION_USER,
  SET_ACCOUNT_SETUP_DATA,
  SET_ARBITRARY_ORGANIZATION_USER,
  SET_BILL_SEARCH_VIEW,
  SET_NOTIFICATIONS,
  SET_ORGANIZATION_USER,
  SET_ORGANIZATION_USERS,
  SET_SUPPORTED_JURISDICTIONS,
} from './types';

// REQUEST NAMES
export const REQUEST_UPDATED_ORGANIZATION = 'RequestUpdatedOrganization';
export const REQUEST_UPDATED_ORG_USER = 'RequestUpdatedOrganizationUser';
export const REQUEST_ACCOUNT_SETUP_DATA = 'RequestAccountSetupData';

// REDUCER
export default function reducer(state: AccountState, action: Action): AccountState {
  switch (action.type) {
    case SET_SUPPORTED_JURISDICTIONS:
      return assign({}, state, {
        supportedJurisdictions: action.jurisdictions,
      });
    case SET_ORGANIZATION_USER:
      const { policyAreas } = action;
      return assign({}, state, {
        organizationUser: action.organizationUser,
        ...(policyAreas ? { policyAreas } : {}),
      });
    case SET_ORGANIZATION_USERS:
      return assign({}, state, {
        organizationUsers: action.organizationUsers,
      });
    case REMOVE_ORGANIZATION_USER:
      return assign({}, state, {
        organizationUsers:
          state.organizationUsers &&
          state.organizationUsers.filter((user) => {
            return user.id !== action.organizationUserId;
          }),
      });
    case SET_ARBITRARY_ORGANIZATION_USER:
      const orgUsers = state.organizationUsers;
      const users = orgUsers
        ? orgUsers.filter((user) => {
            return user.id !== action.organizationUser.id;
          })
        : [];
      return assign({}, state, {
        organizationUsers: users.concat(action.organizationUser),
      });
    case SET_ACCOUNT_SETUP_DATA:
      return assign({}, state, {
        accountSetupData: action.accountSetupData,
      });
    case SET_NOTIFICATIONS:
      return assign({}, state, {
        notifications: action.notifications,
      });
    case SET_BILL_SEARCH_VIEW:
      return assign({}, state, {
        billSearchView: action.billSearchView,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const setSupportedJurisdictions = (jurisdictions: Jurisdiction[]): Action => {
  return {
    type: SET_SUPPORTED_JURISDICTIONS,
    jurisdictions,
  };
};

export const setOrganizationUser = (orgUser: OrganizationUser): Action => {
  const { policyAreas, mfaRegistrations } = orgUser;
  const organizationUser = orgUser;
  organizationUser.mfaEnabled = mfaRegistrations && mfaRegistrations.length > 0;
  Analytics.identifyUser(orgUser);
  Analytics.groupOrganization(orgUser.organizations);
  return {
    type: SET_ORGANIZATION_USER,
    organizationUser,
    ...(policyAreas ? { policyAreas } : {}),
  };
};

export const setArbitraryOrganizationUser = (
  organizationUser: OrganizationUser,
): Action => {
  return {
    type: SET_ARBITRARY_ORGANIZATION_USER,
    organizationUser,
  };
};

const removeOrganizationUser = (organizationUserId: number): Action => {
  return {
    type: REMOVE_ORGANIZATION_USER,
    organizationUserId,
  };
};

export const setOrganizationUsers = (organizationUsers: OrganizationUser[]): Action => {
  return {
    type: SET_ORGANIZATION_USERS,
    organizationUsers,
  };
};

const setAccountSetupData = (accountSetupData: AccountSetupData): Action => {
  return {
    type: SET_ACCOUNT_SETUP_DATA,
    accountSetupData,
  };
};

const setNotifications = (notifications: { data: []; meta: PagerMeta }): Action => {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
};

const setBillSearchView = (billSearchView: BillSearchView): Action => {
  return {
    type: SET_BILL_SEARCH_VIEW,
    billSearchView,
  };
};

// SELECTORS

export const loadOrgUserSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_UPDATED_ORG_USER);
};

export const updateOrgUserInitiated = (): Action => {
  return requestSent(REQUEST_UPDATED_ORG_USER);
};

export const loadOrgSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_UPDATED_ORGANIZATION);
};

export const loadAccountSetupDataSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_ACCOUNT_SETUP_DATA);
};

export const loadOrgUserError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_UPDATED_ORG_USER);
};

export const loadOrgError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_UPDATED_ORGANIZATION);
};

export const loadAccountSetupDataError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_ACCOUNT_SETUP_DATA);
};

// THUNKS
export const getSupportedJurisdictions = (): Thunk => {
  return (dispatch, getState) => {
    const orgUser = getState().account.organizationUser;
    /*
     * If we have a logged-in user, call an authenticated API endpoint to get
     * the list of jurisdictions that user has access to via org membership(s).
     * If we're in an un-authenticated context, call the auth-free jurisdictions
     * API, which will return the list of "free access" jurisdictions for unauthenticated search/etc
     */
    if (!orgUser) {
      instance
        .get('/jurisdictions')
        .then((jurisdictions) => {
          dispatch(setSupportedJurisdictions(jurisdictions.data));
        })
        .catch((error) => {
          console.log('Error fetching jurisdictions unauthenticated', error);
        });
    } else {
      instance
        .get('/supported-jurisdictions')
        .then((jurisdictions) => {
          dispatch(setSupportedJurisdictions(jurisdictions.data));
        })
        .catch((error) => {
          console.error('Error with getSupportedJurisdictions API call', error);
        });
    }
  };
};

export const getOrganizationUser = (): Thunk => {
  return (dispatch) => {
    instance
      .get('/organization-user')
      .then((response) => {
        dispatch(setOrganizationUser(response.data));
      })
      .catch((error) => {
        // We could not load organization user on the basis of our auth token
        // therefore we should log this user out (delete auth token)
        const message = createAuthErrorMessage(error);
        dispatch(setAuthErrorMessage(message));
        if (error.response && [404, 403, 401].includes(error.response.status)) {
          console.error(
            'Could not load organization user with current credentials. Logging out.',
          );
          dispatch(logout());
        } else {
          // unknown error
          console.error('Error with getOrganizationUser API call', error);
        }
      });
  };
};

export const getOrganizationUsers = (
  orgId?: number,
  state?: string,
  status?: string,
  notification?: string,
  reasonsForUsingEnview?: string,
  legislativeWorkExperience?: string,
  helpLevelNeeded?: string,
): Thunk => {
  return (dispatch) => {
    const query: string[] = [];
    if (orgId) {
      query.push(`orgId=${orgId}`);
    }
    if (state) {
      query.push(`state=${state}`);
    }
    if (status) {
      query.push(`status=${status}`);
    }
    if (notification) {
      query.push(`notification=${notification}`);
    }
    if (reasonsForUsingEnview) {
      query.push(`reasonsForUsingEnview=${reasonsForUsingEnview}`);
    }
    if (legislativeWorkExperience) {
      query.push(`legislativeWorkExperience=${legislativeWorkExperience}`);
    }
    if (helpLevelNeeded) {
      query.push(`helpLevelNeeded=${helpLevelNeeded}`);
    }

    instance
      .get(`/organization-users?${query.join('&')}`)
      .then((orgUserRes) => {
        dispatch(setOrganizationUsers(orgUserRes.data.data));
      })
      .catch((error) => {
        console.error('Error with getOrganizationUsers API call', error);
      });
  };
};

const userOrgNotifStringify = (userSettings: UserSettings | undefined) => {
  return pick(userSettings, [...Object.values(EmailNotifications)]);
};

export const updateOrganizationUser = (
  changedOrgUser: Partial<OrganizationUser>,
  currentOrgUser: OrganizationUser,
): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(REQUEST_UPDATED_ORG_USER));
    instance
      .put('/organization-user', changedOrgUser)
      .then((response) => {
        const organizationUser = response.data;
        const changedUserNotifSettings = userOrgNotifStringify(changedOrgUser.settings);
        const currentUserNotifSettings = userOrgNotifStringify(currentOrgUser.settings);
        if (
          changedOrgUser.settings &&
          !isEqual(changedUserNotifSettings, currentUserNotifSettings)
        ) {
          Analytics.trackNotificationSettingUpdated(organizationUser);
        }
        if (changedOrgUser.password) {
          Analytics.trackPasswordUpdated(organizationUser);
        }
        Analytics.trackProfileUpdated(
          currentOrgUser,
          organizationUser,
          ProfileSettingsSection.ABOUT_ME,
        );
        dispatch(setOrganizationUser(organizationUser));
        dispatch(requestSuccess(REQUEST_UPDATED_ORG_USER));
      })
      .catch((error) => {
        dispatch(requestFailure(REQUEST_UPDATED_ORG_USER, error));
      });
  };
};

export const updateArbitraryOrganizationUser = (orgUser: OrganizationUser): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(REQUEST_UPDATED_ORG_USER));

    instance
      .put(`/organization-users/${orgUser.id}`, orgUser)
      .then((response) => {
        dispatch(setArbitraryOrganizationUser(response.data));
        dispatch(requestSuccess(REQUEST_UPDATED_ORG_USER));
      })
      .catch((error) => {
        dispatch(requestFailure(REQUEST_UPDATED_ORG_USER, error));
      });
  };
};

export const deleteOrganizationUser = (orgUserId: number): Thunk => {
  return (dispatch) => {
    instance
      .delete(`/organization-users/${orgUserId}`)
      .then(() => {
        dispatch(removeOrganizationUser(orgUserId));
      })
      .catch((error) => {
        console.error('Error with deleteOrganizationUser API call', error);
      });
  };
};

export const getAccountSetupData = (): Thunk => {
  return (dispatch) => {
    instance
      .get(`/onboarding?onboardingStage=${OnboardingStage.ACCOUNT}`)
      .then((res) => {
        const { data } = res;
        dispatch(setAccountSetupData(data.data));
      })
      .catch((error) => {
        console.error('Error getting registration state', error);
        const message = createAuthErrorMessage(error);
        dispatch(setAuthErrorMessage(message));
      });
  };
};

export const updateAccountSetupData = (
  formData: AccountOnboardingUserData,
  prevOrgUser: OrganizationUser,
): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(REQUEST_ACCOUNT_SETUP_DATA));
    instance
      .put(`/organization-user/updateRegistration`, formData)
      .then((res) => {
        const { data } = res;
        dispatch(getAccountSetupData());
        dispatch(requestSuccess(REQUEST_ACCOUNT_SETUP_DATA));
        Analytics.trackProfileUpdated(
          prevOrgUser,
          data,
          ProfileSettingsSection.ABOUT_MY_WORK,
        );
      })
      .catch((error) => {
        console.error('Error updating account settings', error);
        const message = createAuthErrorMessage(error);
        dispatch(setAuthErrorMessage(message));
        dispatch(requestFailure(REQUEST_ACCOUNT_SETUP_DATA));
      });
  };
};

export const resetOrgUpdate = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_UPDATED_ORGANIZATION));
  };
};

export const resetOrgUserUpdate = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_UPDATED_ORG_USER));
  };
};

export const resetAccountSetupDataUpdate = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_ACCOUNT_SETUP_DATA));
  };
};

export const deleteMfaRegistration =
  (orgId: number, userId: number): Thunk =>
  async (dispatch) => {
    await instance.post(`/organization/delete-mfa-for-user/${orgId}/${userId}`);
    // Top level org team is 0
    // and canViewTeamMfa is assumed because this is an admin action
    dispatch(getTeamMembers(1, true));
  };

export const loadNotifications =
  (pageNumber = 0, order = 'DESC', limit = 20): Thunk =>
  async (dispatch) => {
    let offset = 0;
    if (pageNumber) {
      offset = (pageNumber - 1) * limit;
    }
    const response = await instance.get(
      `/notifications?limit=${limit}&offset=${offset}&order=${order}`,
    );
    dispatch(setNotifications(response.data));
  };

export const getBillSearchView = (teamId: number): Thunk => {
  return (dispatch, getState) => {
    const orgUser = getState().account.organizationUser;
    if (!orgUser) {
      dispatch(setBillSearchView(BillSearchView.Card));
    } else {
      instance
        .get(`/teams/${teamId}/organization-user/workspace-settings`)
        .then((response) => {
          const { data } = response;
          const view: BillSearchView = data.billSearchView;
          dispatch(setBillSearchView(view));
        })
        .catch((error) => {
          console.error('Error getting user-workspace settings', error);
        });
    }
  };
};

export const updateBillSearchView = (
  teamId: number,
  billView: BillSearchView,
): Thunk => {
  return (dispatch) => {
    instance
      .put(`/teams/${teamId}/organization-user/workspace-settings`, {
        settings: {
          billSearchView: billView,
        },
      })
      .then((response) => {
        const { data } = response;
        const view: BillSearchView = data.billSearchView;
        Analytics.trackBillViewChange(data.organizationUserId, data.teamId, view);
        dispatch(setBillSearchView(view));
      })
      .catch((error) => {
        console.error('Error updating user-workspace-settings', error);
      });
  };
};
