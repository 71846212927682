import React from 'react';
import JurisdictionAdapter from './Adapter';

class ILAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^First Reading|^Filed with Secretary/;
    this.textStyleRules = {
      headers: [
        [/^AN ACT concerning|^HOUSE RESOLUTION|^ARTICLE \d+/],
        [/^BE IT ENACTED BY THE PEOPLE|^REPRESENTED IN THE GENERAL ASSEMBLY/i],
        [/^WHEREAS,|^RESOLVED,/],
      ],
      listItems: [[/^\([a-z]+\)/], [/^\([0-9]+\)/]],
      inline: [
        {
          regex:
            /^(\s*Section|\s*Sec\.) [0-9a-z]+\.+([^0-9.].+)?|^(\s*Section|\s*Sec\.) [0-9a-z]+-[0-9a-z]+\.+([^0-9.].+)?/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-14" key={key}>
              {node}
            </strong>
          ),
        },
      ],
    };
  }
}

export default ILAdapter;
