import React from 'react';
import JurisdictionAdapter from './Adapter';

class IAAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^Introduced,/;
    this.textStyleRules = {
      headers: [
        [/^An Act relating to |^An Act requiring that/],
        [/^BE IT ENACTED BY THE/],
        [
          /^Section \d+\.(?![0-9])|^Sec\. [0-9]+\.(?![0-9])|Sec\. _+\.|^DIVISION [MDCLXVI]$/,
        ],
      ],
      listItems: [[/^[0-9]+\. /], [/^[a-z]\. /]],
      inline: [
        {
          regex: /^(Section|Sec\.) [0-9a-z.]+([^0-9.].+)?/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-16" key={key}>
              {node}
            </strong>
          ),
        },
      ],
    };
  }
}

export default IAAdapter;
