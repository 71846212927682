import React, { ReactElement } from 'react';
import icon from '../../static/plural-icon.svg';
import closeIcon from '../../static/x.svg';
import emptyDot from '../../static/orange-dot.svg';
import filledDot from '../../static/orange-dot-filled.svg';
import { Link } from 'react-router-dom';
import AnalyzerTopBar from './AnalyzerTopBar';
const AnalyzerLogInOverlay = (): ReactElement => {
  return (
    <>
      <AnalyzerTopBar />
      <main className="login-overlay">
        <div className="login-card-wrapper">
          <Link to="/analyzer/">
            <img className="close-icon" src={closeIcon} />
          </Link>
          <div className="login-card">
            <img src={icon} />
            <h2>Plural Policy Analyzer</h2>
            <p>
              Analyze thousands of words of bill text in an instant – that’s the power
              of Plural.
            </p>
            <p>
              <strong>Access for $29/month</strong>
            </p>

            <div className="buttons">
              <a
                className="login-button google"
                href={`${process.env.REACT_APP_API_URL}/v2/auth/google`}
                role="button"
              >
                <span className="google-text">Sign up now with Google</span>
              </a>
              <a
                className="login-button"
                href={`${process.env.REACT_APP_API_URL}/v2/auth/google`}
                role="button"
              >
                <span className="google-text">Log in</span>
              </a>
            </div>

            <div className="process">
              <span>
                <img src={filledDot} />
                Sign Up
              </span>
              <span>
                <img src={emptyDot} />
                Account Set Up
              </span>
              <span>
                <img src={emptyDot} />
                Search & Analyze
              </span>
            </div>

            <Link className="faq-link" to="/analyzer#faq">
              FAQs
            </Link>

            <div className="terms">
              By using Plural you agree to our{' '}
              <a href="https://pluralpolicy.com/terms-of-use/" target="_blank">
                Terms of Use
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AnalyzerLogInOverlay;
