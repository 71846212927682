import React from 'react';
import JurisdictionAdapter from './Adapter';

class ALAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^Read for the first time/;
    this.textStyleRules = {
      headers: [
        [/^(SB|HB)\d+$|^ARTICLE \d+/],
        [
          /^A BILL|^TO BE ENTITLED|^AN ACT|BE IT ENACTED BY THE LEGISLATURE OF ALABAMA/i,
        ],
        [/^Section \d+\.(?![0-9])/i],
      ],
      listItems: [[/^"?\([a-z]+\)/], [/^\([a-z]+\)/]],
      inline: [
        {
          regex: /^(WHEREAS,|BE IT FURTHER RESOLVED,)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <span className="inline-section-text-14" key={key}>
              {node}
            </span>
          ),
        },
      ],
    };
  }
}

export default ALAdapter;
