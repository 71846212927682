/**
 * JurisdictionAdapter encapsulates logic specific to a legislative jursidiction
 * This base class provides common functionality
 */
class JurisdictionAdapter {
  constructor() {
    this.actionClassifiers = {
      introduced: /^Introduced\.|^Read first time\./,
    };
    this.textStyleRules = {
      headers: [],
      listItems: [],
      inline: [],
    };
    this.previousLineStyles = [];
  }

  findIntroducedAction(actions) {
    const introRegx = this.actionClassifiers.introduced;
    const matchingActions = actions.filter((action) =>
      introRegx.test(action.description),
    );

    // find the most best matching action, or failing that, undefined
    return matchingActions.reduce((bestAction, action) => {
      // no match yet, so this is the best one
      if (!bestAction) {
        return action;
      }

      // this action has a date, and its either earlier than the prior best, or just exists
      if (
        action.actionDate &&
        (!bestAction.actionDate || action.actionDate < bestAction.actionDate)
      ) {
        return action;
      }

      // no action dates, but this action's ID is lower (earlier)
      if (!action.actionDate && !bestAction.actionDate && action.id < bestAction.id) {
        return action;
      }

      // OK we didn't find a better match
      return bestAction;
    }, undefined);
  }

  lineStyleGenerator(content, startingClasses) {
    const styleClasses = startingClasses;

    // Get plain text version of content
    if (!this.DOMParser) {
      // warning: DOMParser only supported natively in browser, needs npm package xmldom otherwise
      this.DOMParser = new DOMParser();
    }
    const document = this.DOMParser.parseFromString(content, 'text/html');
    const plainText = (document.body.textContent || '').trim();

    // is this line a header?
    this.textStyleRules.headers.forEach((rules, levelIndex) => {
      rules.forEach((rule) => {
        if (rule.test(plainText)) {
          styleClasses.push(`header-${levelIndex + 1}`);
          styleClasses.push('vertical-break');
        }
      });
    });

    // is this line a list item?
    this.textStyleRules.listItems.forEach((rules, levelIndex) => {
      rules.forEach((rule) => {
        if (rule.test(plainText)) {
          styleClasses.push(`list-item-${levelIndex + 1}`);
          styleClasses.push('vertical-break');
        }
      });
    });

    return styleClasses;
  }

  static sortBillVersions(versions) {
    return versions;
  }
}

export default JurisdictionAdapter;
