import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BillAPI } from '../../api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import BillSummaryDisplay from './BillSummaryDisplay';

type BillSummaryUrlParams = {
  billId: string;
};

const AnalyzerBillSummary = (): ReactElement => {
  const params = useParams<BillSummaryUrlParams>();
  const billId = params.billId;

  const {
    data: bill,
    isSuccess: hasBillData,
    isFetching: isBillFetching,
    isError: isBillError,
  } = BillAPI.endpoints.getBill.useQuery(
    billId
      ? {
          billId,
          teamId: undefined,
        }
      : skipToken,
  );

  const getIntroducedVersionId = (): number | undefined => {
    if (bill && bill.versions && bill.versions.length > 0) return bill.versions[0].id;
    return undefined;
  };

  const introducedVersionId = useMemo(() => getIntroducedVersionId(), [bill]);

  const {
    data: aiSummary,
    isError: isAiSummaryError,
    isFetching: isAiSummaryFetching,
  } = BillAPI.endpoints.getBillAutoSummary.useQuery(
    bill && introducedVersionId
      ? {
          billId: bill.id,
          billVersionId: introducedVersionId,
        }
      : skipToken,
  );

  const allowsSummaries =
    hasBillData &&
    !['usa', 'dc', 'hi', 'ma', 'tn'].includes(bill.session.state.abbreviation);

  return (
    <div className="demonstration-summary">
      {!billId && (
        <div>Use the search controls above to search for a bill by bill number</div>
      )}
      {isBillFetching && <div>Loading...</div>}
      {isBillError && <div>Error occurred loading bill details</div>}
      {hasBillData && allowsSummaries && (
        <BillSummaryDisplay
          aiSummaryData={aiSummary}
          aiSummaryDataError={isAiSummaryError}
          aiSummaryDataFetching={isAiSummaryFetching}
          bill={bill}
          introducedVersionId={introducedVersionId || -1}
        />
      )}
      {hasBillData && !allowsSummaries && (
        <div>
          Analysis for this jurisdiction is under construction -- Check back soon!
        </div>
      )}
    </div>
  );
};

export default AnalyzerBillSummary;
