import './BillText.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import React, { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import TextButton from '../../Shared/Buttons/TextButton';
import {
  openEditUserActionModal,
  openReplyUserActionModal,
  getCardRef,
  setBillSidebarCurrentTab,
} from '../../../dux';
import * as Analytics from '../../../analytics/UserActionAnalytics';
import { State } from '../../../dux/@types';
import { BillSidebarTabs } from '../../../helpers/BillHelper';
import 'react-tooltip/dist/react-tooltip.css';

type BillAnnotationTooltipProps = {
  annotation: UserAction;
};

const REPLY_TEXT = 'Reply';
const JUMP_TO_NOTE = 'Jump to note';

const BillAnnotationTooltip: FunctionComponent<BillAnnotationTooltipProps> = (
  props,
) => {
  const { annotation, children } = props;
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const ref = useSelector((state: State) => getCardRef(state, annotation.id));
  const openTab = useSelector((state: State) => state.billView.billSidebarCurrentTab);
  const dispatch = useDispatch();

  const id = `${annotation.id}`;

  const target = (
    <span className="annotation-highlight" data-iscapture="true" data-tip id={id}>
      {children}
    </span>
  );

  const jumpToComment = useCallback(() => {
    Analytics.trackJumpToComment(annotation);
    const delay = openTab === BillSidebarTabs.ACTIVITY ? 0 : 500;
    if (delay > 0) {
      dispatch(setBillSidebarCurrentTab(BillSidebarTabs.ACTIVITY));
    }
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, delay);
  }, [dispatch, openTab, annotation, ref]);

  const tooltip = (anno: UserAction) => {
    return (
      <ReactTooltip
        anchorId={id}
        className="bill-annotation-tooltip tooltip-content"
        clickable
        delayHide={200}
        delayShow={200}
      >
        <>
          <div className="tooltip-options">
            <TextButton
              className="tooltip-button"
              label={REPLY_TEXT}
              onClick={() => dispatch(openReplyUserActionModal(anno))}
            />
            <TextButton
              className="tooltip-button"
              label={JUMP_TO_NOTE}
              onClick={jumpToComment}
            />
            {orgUser && anno.organizationUser.id === orgUser.id && (
              <FontAwesomeIcon
                className="edit"
                color="#979797"
                icon={faPen}
                onClick={() => dispatch(openEditUserActionModal(anno))}
                size="sm"
              />
            )}
          </div>
          <div className="comment">{anno.comment}</div>
        </>
      </ReactTooltip>
    );
  };

  return (
    <span className="bill-note-hover">
      {target}
      {tooltip(annotation)}
    </span>
  );
};

export default BillAnnotationTooltip;
