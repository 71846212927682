import React from 'react';
import JurisdictionAdapter from './Adapter';

class MNAdapter extends JurisdictionAdapter {
  constructor() {
    super();

    this.actionClassifiers.introduced = /^Introduction|^Resolution introduced/;
    this.textStyleRules = {
      headers: [
        [/^a bill for an act|^a resolution|^article \d+$/i],
        [],
        [/^Be It Enacted/i],
      ],
      listItems: [[/^\([a-z]+\)/], [/^\([0-9]+\)/]],
      inline: [
        {
          regex: /^(Section|Sec\.) [0-9.]+([^0-9.].+)?/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-16" key={key}>
              {node}
            </strong>
          ),
        },
        {
          regex: /^(Subdivision \d+\.(?![0-9])|Subd. \d+\.(?![0-9]))(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <strong className="inline-section-text-14" key={key}>
              {node}
            </strong>
          ),
        },
        {
          regex: /^(WHEREAS,|BE IT FURTHER RESOLVED,)(.*)/,
          nonMatchingGroup: 2,
          wrap: (node, key) => (
            <span className="inline-section-text-14" key={key}>
              {node}
            </span>
          ),
        },
      ],
    };
  }
}

export default MNAdapter;
