import { BillDetailed } from '@enview/interface/types/bills/Bill';
import { ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import BillAnnotationFromSelection from '../../../containers/BillAnnotationFromSelection2';
import { State } from '../../../dux/@types';
import featureFlags, { FrontendFeature } from '../../../featureFlags';
import getJurisdictionAdapter from '../../../helpers/jurisdictions/index';
import parseCEML from '../../../helpers/parseCEML';
import BillTextPhraseSearchPeek from './BillTextPhraseSearchPeek';

type BillTextProps = {
  diff: string;
  bill: BillDetailed;
  showChanges: boolean;
  debug: boolean;
  billVersionId?: number;
  annotationsEnabled?: boolean;
};

const BillText = (props: BillTextProps): ReactElement => {
  const { diff, bill, showChanges, debug, billVersionId, annotationsEnabled } = props;
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const debugModeAvailable = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.BillTextDebug,
    orgUser,
  );
  const billTextRef = useRef<HTMLDivElement>(null);

  const jurisdictionAdapter = getJurisdictionAdapter(bill.session.state.abbreviation);
  const billLines = parseCEML(bill.id, diff, jurisdictionAdapter, billVersionId);
  const changesClass = showChanges ? 'show-changes' : 'hide-changes';
  const billTextClass = `${changesClass} bill-text`;

  // Allow disabling annotation controls, but default to enabled
  const renderAnnotationControls =
    annotationsEnabled === undefined || annotationsEnabled === true;
  return (
    <div id="billView">
      <div className={billTextClass} id="billText" ref={billTextRef}>
        {billLines}
      </div>
      {renderAnnotationControls && (
        <BillAnnotationFromSelection
          annotationsEnabled={annotationsEnabled}
          bill={bill}
          billVersionId={billVersionId}
        />
      )}
      {debugModeAvailable && <BillTextPhraseSearchPeek billTextRef={billTextRef} />}

      {debug && <pre id="billTextDebug">{diff}</pre>}
    </div>
  );
};

export default BillText;
