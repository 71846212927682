import React, { ReactChild } from 'react';

type BillTextLineProps = {
  page: string;
  line: string;
  children: ReactChild;
  displayNumber: boolean;
  styleClasses: string;
};

const BillTextLine = React.forwardRef<HTMLDivElement, BillTextLineProps>(
  (props, ref) => {
    const lineClass = `row bill-text-row bill-text-line ${props.styleClasses}`;
    return (
      <div
        className={lineClass}
        data-number={props.line}
        data-page={props.page}
        ref={ref}
      >
        <div className="col-auto line-number-col">
          {props.displayNumber && (
            <span className="line-number">
              {' '}
              {props.page}.{props.line}
            </span>
          )}
        </div>
        <div className="col line-content-col">
          <span className="line-content">{props.children}</span>
        </div>
      </div>
    );
  },
);

export default BillTextLine;
