import GenericAdapter from './Adapter';
import ALAdapter from './AL';
import GAAdapter from './GA';
import CAAdapter from './CA';
import IAAdapter from './IA';
import ILAdapter from './IL';
import LAAdapter from './LA';
import MIAdapter from './MI';
import MNAdapter from './MN';
import USAAdapter from './USA';

/**
 * Service to load the correct jurisdiction adapter
 * @param {string} jurisdictionIdentifier Identifier in the form of state abbreviation or federal
 * @return {*}
 */
export default (jurisdictionIdentifier) => {
  const identifier = jurisdictionIdentifier
    ? jurisdictionIdentifier.toLowerCase()
    : 'federal';
  switch (identifier) {
    case 'al':
      return new ALAdapter();
    case 'ca':
      return new CAAdapter();
    case 'ga':
      return new GAAdapter();
    case 'ia':
      return new IAAdapter();
    case 'il':
      return new ILAdapter();
    case 'la':
      return new LAAdapter();
    case 'mi':
      return new MIAdapter();
    case 'mn':
      return new MNAdapter();
    case 'usa':
      return new USAAdapter();
    default:
      return new GenericAdapter();
  }
};
